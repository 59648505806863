
import Vue from "../../AppVue";
import Component from "vue-class-component";
import Utils from "../../common/Utils";
import { Prop } from "vue-property-decorator";
import StoreService from "../../common/services/StoreService";
import SERVICE_IDENTIFIERS from "../../config/ServiceIdentifiers";
import container from "../../config/InversifyConfig";

@Component({
  components: {},
})
export default class FormComponent extends Vue {
  @Prop({ required: false }) formName!: string;
  modelId!: string;
  public model: any = {};
  public oldModel: any = {};
  formErrors: { [fieldId: string]: string } = {};

  watcherId!: string;
  clearErrorsStoreKey = "form.errors.clear";

  $refs!: any;

  storeService = container.get<StoreService>(SERVICE_IDENTIFIERS.StoreService);

  destroyed() {
    this.storeService.removeWatcher(this.watcherId, this.clearErrorsStoreKey);
  }

  created() {
    this.watcherId = "form_" + this.generateRandomNumber();
    this.storeService.watchUpdate(
      this.clearErrorsStoreKey,
      this.watcherId,
      () => {
        this.clearErrors(false);
      },
      false
    );
  }

  clearErrors(triggerWatchers: boolean) {
    this.formErrors = Object.assign({});

    if (triggerWatchers) {
      this.storeService.set(this.clearErrorsStoreKey, null, {
        alertWatchers: true,
      });
    }
  }

  validateField(fieldName: string) {
    this.clearErrors(true);

    // const isValid = await this.$refs.observer.validate({ silent: true });
    // if (!isValid) {
    //   const errorArray = this.$refs.observer.errors[fieldName];
    //   if (errorArray.length > 0) {
    //     this.formErrors[fieldName] = errorArray[0];
    //   }
    // }
  }

  validateForm(): Promise<boolean> {
    return this.$refs.observer.validate().then((success: boolean) => {
      this.clearErrors(false);

      if (success) {
        return true;
      }

      const errorKeys = Object.keys(this.$refs.observer.errors);

      let firstEl!: HTMLElement;
      errorKeys.forEach((errorKey) => {
        const errorArray = this.$refs.observer.errors[errorKey];

        if (errorArray.length > 0) {
          this.formErrors[errorKey] = errorArray[0];
          const el = this.$el.querySelector(`#${errorKey}`) as HTMLElement;

          if (Utils.isUndefined(firstEl)) {
            firstEl = el;
          }
        }
      });

      if (!Utils.isUndefined(firstEl) && firstEl != null) {
        this.$nextTick(() => {
          this.$refs.form;
          firstEl.focus();
        });
      }
      return false;
    });
  }

  generateRandomNumber() {
    const random = Math.random() * 1000;
    return random.toFixed(0);
  }
}
