import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 640,
      md: 960,
      lg: 1280,
    },
    scrollBarWidth: 24,
  },
  icons: {
    iconfont: "mdi",
  },
});
