
import Component from "vue-class-component";
import Utils from "../../../common/Utils";
import { Route } from "vue-router";
import StoreService from "../../../common/services/StoreService";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import { STORE_KEY_CONFIRM_DIALOG } from "../../../config/Constants";
import { ConfirmDialogData } from "../../../common/Models";
import FormComponent from "../FormComponent.vue";

@Component({
  components: {},
  beforeRouteLeave: function (to: Route, from: Route, next: Function) {
    const thisPage: FormPage = this as FormPage;

    if (thisPage.hasUnsubmittedChanges()) {
      const text =
        "Ha elhagyja az oldalt, az aktuális módosítások nem kerülnek mentésre. Folytatja a műveletet?";
      const confirmDialogData: ConfirmDialogData =
        Utils.createUnsavedConfirmDialogData(text, next);
      thisPage.storeService.set(STORE_KEY_CONFIRM_DIALOG, confirmDialogData);
    } else {
      next();
    }
  },
})
export default class FormPage extends FormComponent {
  private submitted: boolean = false;
  private beforeRouteLeaveFnOverwritten: boolean = false;

  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );

  getSubmitted() {
    return this.submitted;
  }

  setSubmitted(submitted: boolean) {
    this.submitted = submitted;
  }

  setBeforeRouteLeaveFnOverwritten() {
    this.beforeRouteLeaveFnOverwritten = true;
  }

  hasUnsubmittedChanges(): boolean {
    return (
      !Utils.isEmptyObject(
        Utils.modelChangedProps(this.oldModel, this.model)
      ) &&
      !this.getSubmitted() &&
      !this.beforeRouteLeaveFnOverwritten
    );
  }
}
