
import Vue from "../../../AppVue";
import { OkDialogData } from "../../../common/Models";
import StoreService from "../../../common/services/StoreService";
import { STORE_KEYS } from "../../../common/Store";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import Component from "vue-class-component";

@Component({})
export default class OkDialog extends Vue {
  data: any = {
    openDialog: false,
    dialogData: {},
  };

  storeService = container.get<StoreService>(SERVICE_IDENTIFIERS.StoreService);

  destroyed() {
    this.storeService.removeWatcher("OkDialog", STORE_KEYS.DIALOG_OK);
  }

  created() {
    this.storeService.watchUpdate(
      STORE_KEYS.DIALOG_OK,
      "OkDialog",
      (dialogData: OkDialogData) => {
        this.data["dialogData"] = dialogData;
        this.data["openDialog"] = true;
      }
    );
  }

  get dialogText() {
    if (this.data.dialogData) {
      return this.data.dialogData.text || "";
    }
    return "";
  }

  ok() {
    this.data.dialogData.afterOkFn(this.data.dialogData.ctx);
    this.closeDialog();
  }

  closeDialog() {
    this.$set(this.data, "openDialog", false);
    this.$set(this.data, "dialogData", {});
    this.storeService.set(STORE_KEYS.DIALOG_OK, null, { alertWatchers: false });
  }
}
