
import Vue from "../../AppVue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CustomContextMenuIf, {
  SortingPagination,
  ContextMenuItem,
  ContextMenuData,
} from "../../common/Models";
import {
  PAGINATION_ITEMS_PER_PAGE,
  DATE_FORMAT_HU,
  TIME_FORMAT,
  PAGINATION_TOTAL_VISIBLE,
  INTEGER_MIN_VALUE,
  STORE_KEY_CONTEXT_MENU,
} from "../../config/Constants";
import Utils from "../../common/Utils";
import StoreService from "../../common/services/StoreService";
import SERVICE_IDENTIFIERS from "../../config/ServiceIdentifiers";
import moment, { Moment } from "moment-timezone";
import container from "../../config/InversifyConfig";

@Component({})
export default class ItemList extends Vue implements CustomContextMenuIf {
  @Prop({ default: true }) selectableItems!: boolean;
  @Prop({ required: true }) storePrefix!: string;
  @Prop({ required: true }) loading!: boolean;
  @Prop({ required: true }) defaultSorting!: string;
  @Prop({ default: (item: any) => item["id"] }) rowId!: (item: any) => any;
  @Prop({ required: false }) hasCustomContextMenu!: boolean;

  storeService = container.get<StoreService>(SERVICE_IDENTIFIERS.StoreService);

  options: any = {};
  sortingPagination: SortingPagination = {};

  currentPage: number = 1;
  private itemsPerPageValue = PAGINATION_ITEMS_PER_PAGE;
  itemsPerPageSelection = [25, 50, 100];
  totalVisiblePages = PAGINATION_TOTAL_VISIBLE;

  items: any[] = [];
  totalItems: number = 0;
  selected: any[] = [];

  tableElement!: HTMLTableElement | null;
  customContextMenuApplied: boolean = false;

  applyFixedHeader: boolean = false; // if true, add fixed-header attr to table too!
  tableHeight: string = "48"; // default row height of Vuetify data table
  tableMaxHeightRatio: number = 0.7;

  get itemsPerPage() {
    return this.itemsPerPageValue;
  }

  set itemsPerPage(value: number) {
    this.itemsPerPageValue = value;
    this.onItemsPerPageChange();
  }

  destroyed() {
    console.log("Destroyed itemlist");
    this.storeService.removeWatcher(ItemList.name, this.storePrefix + ".list");
    this.storeService.removeWatcher(ItemList.name, this.storePrefix + ".total");
    this.storeService.removeWatcher(ItemList.name, this.storePrefix + ".page");
  }

  setCustomContextMenuFn(): void {
    if (
      !this.$el ||
      !this.$el.querySelector ||
      typeof this.$el.querySelector !== "function"
    ) {
      return;
    }

    this.tableElement = this.$el.querySelector("table");
    if (this.tableElement) {
      this.tableElement.addEventListener("contextmenu", (evt: MouseEvent) => {
        evt.preventDefault();
        evt.stopPropagation();

        const target = evt.target;

        // no custom context menu if clicked on actions cell
        if (this.isActionsCell(target)) {
          return true;
        }

        const tableRow: any = this.getParentByNodename(target, "TR");
        if (tableRow) {
          const contextMenuRowItem = this.getItemFromClickedRow(tableRow);
          this.onContextMenuRowItemChanged(
            evt,
            contextMenuRowItem,
            evt.clientX,
            evt.clientY
          );
        }
        // disable default context menu by returning false value
        return false;
      });
    }
  }

  onContextMenuRowItemChanged(
    evt: MouseEvent,
    rowItem: any,
    menuPosX: number,
    menuPosY: number
  ): void {
    if (rowItem) {
      const contextMenuItems: ContextMenuItem[] = [];
      this.setContextMenuItems(contextMenuItems, rowItem);

      const contextMenuData: ContextMenuData = {
        ctx: this,
        menuItems: contextMenuItems,
        positionX: menuPosX,
        positionY: menuPosY,
      };

      this.storeService.set(STORE_KEY_CONTEXT_MENU, contextMenuData);
    }
  }

  // dummy
  setContextMenuItems(
    contextMenuItems: ContextMenuItem[],
    contextMenuRowItem: any
  ): void {}

  private getItemFromClickedRow(tableRow: any): any {
    // elem with not empty id attribute
    const elemWithId = tableRow.querySelector("div[id]:not([id=''])");
    if (elemWithId) {
      let rowId = elemWithId["id"];
      rowId = rowId.replace("id-", "");
      const foundItem = this.items.find(
        (item) => this.rowId(item) + "" == rowId
      );
      return foundItem || null;
    }
    return null;
  }

  private getParentByNodename(el: any, searchNodeName: string): HTMLElement {
    const nodeName = el["nodeName"];
    if (nodeName == searchNodeName.toUpperCase()) {
      return el;
    }
    const parent = el["parentNode"];
    return this.getParentByNodename(parent, searchNodeName);
  }

  private isActionsCell(el: any): boolean {
    const parentCell = this.getParentByNodename(el, "td");
    if (parentCell) {
      const actionsDiv = parentCell.querySelector("div[id='actions']");
      return !Utils.isUndefined(actionsDiv) && actionsDiv != null;
    }
    return false;
  }

  created() {
    this.sortingPagination = {};

    const searchModel =
      this.storeService.get(this.storePrefix + ".searchModel") || {};

    if (!Utils.isEmptyObject(searchModel)) {
      this.currentPage = searchModel.offset + 1;
      this.itemsPerPageValue = searchModel.count;

      // update sort info
      const sortByDesc = Utils.toOrderByDesc(searchModel.sortParams);
      this.$set(this.options, "sortBy", [sortByDesc.sortBy]);
      this.$set(this.options, "sortDesc", [sortByDesc.sortDesc]);
    }

    this.storeService.watchUpdate(
      this.storePrefix + ".list",
      ItemList.name,
      (items: any[]) => {
        this.items = items;
        console.log("changed item list");
      },
      true
    );
    this.storeService.watchUpdate(
      this.storePrefix + ".total",
      ItemList.name,
      (totalItems: number) => {
        this.totalItems = totalItems;
        console.log("changed item list total: " + JSON.stringify(totalItems));
      },
      true
    );
    this.storeService.watchUpdate(
      this.storePrefix + ".page",
      ItemList.name,
      (page: number) => {
        if (this.currentPage != page) {
          this.currentPage = page;
          console.log("changed item list currentPage: " + JSON.stringify(page));
        }
      },
      true
    );
  }

  updated() {
    this.$nextTick(() => {
      // set custom context menu
      if (this.hasCustomContextMenu && !this.customContextMenuApplied) {
        this.setCustomContextMenuFn();
        this.customContextMenuApplied = true;
        console.log("Set custom context menu");
      }
    });
  }

  get paginationDisabled() {
    return this.totalItems <= this.itemsPerPage;
  }

  get pageCount() {
    const pageCount = Math.floor(this.totalItems / this.itemsPerPage);
    const rem = this.totalItems % this.itemsPerPage;
    return rem > 0 ? pageCount + 1 : pageCount;
  }

  @Watch("items", { deep: true, immediate: true })
  onItemsChange() {
    if (this.applyFixedHeader) {
      this.$nextTick(() => {
        this.tableHeight = this.calcTableHeight();
        this.$forceUpdate();
      });
    }

    // re-set context menu for new rows
    this.customContextMenuApplied = false;
  }

  calcTableHeight() {
    if (this.$el && this.$el.querySelector) {
      const tBody: HTMLElement | null = this.$el.querySelector("table>tbody");

      if (tBody) {
        const tableMaxHeight = window.innerHeight * this.tableMaxHeightRatio;
        const tBodyHeight = tBody.scrollHeight;

        if (tBodyHeight < tableMaxHeight) {
          return "auto";
        }
      }
    }
    return this.tableMaxHeightRatio * 100 + "vh";
  }

  // sorting
  @Watch("options")
  onOptionsChange() {
    this.sortingPagination.sortParams = this.newSorting();
    let alertWatchers = undefined;

    if (this.items.length === this.totalItems) {
      console.log("sorting locally");
      alertWatchers = false;

      const sp: any = Utils.toOrderByDesc(this.sortingPagination.sortParams);
      this.sortLocally(sp.sortBy, sp.sortDesc);
    } else {
      console.log("sorting backend");
      alertWatchers = true;

      // if sorting changed => jump to first page
      this.sortingPagination.offset = 0;
      this.currentPage = 1;
    }

    const searchModel = this.storeService.get(
      this.storePrefix + ".searchModel"
    );
    if (searchModel.sortParams != this.sortingPagination.sortParams) {
      // update searchmodel
      console.log("update search model");

      this.storeService.update(
        this.storePrefix + ".searchModel",
        this.sortingPagination,
        { alertWatchers: alertWatchers }
      );
    }
  }

  private newSorting() {
    return this.options.sortBy.length === 0
      ? this.defaultSorting
      : (this.options.sortDesc[0] ? "-" : "+") + this.options.sortBy[0];
  }

  private sortLocally(sortBy: string, sortDesc: boolean) {
    this.items.sort((a, b) => {
      const sortA = a[sortBy];
      const sortB = b[sortBy];
      if (moment(sortA, DATE_FORMAT_HU + " " + TIME_FORMAT, true).isValid()) {
        // date
        console.log("date sorting");

        const dA: Moment = moment(
          sortA,
          DATE_FORMAT_HU + " " + TIME_FORMAT,
          true
        );
        const dB: Moment = moment(
          sortB,
          DATE_FORMAT_HU + " " + TIME_FORMAT,
          true
        );
        if (sortDesc) {
          if (dA.isBefore(dB)) return 1;
          if (dA.isAfter(dB)) return -1;
          return 0;
        } else {
          if (dA.isBefore(dB)) return -1;
          if (dA.isAfter(dB)) return 1;
          return 0;
        }
      } else if (
        Number.parseFloat(sortA) &&
        Number.parseFloat(sortA).toString() == sortA + ""
      ) {
        // number
        console.log("number sorting");

        const nA: number = Number.parseFloat(sortA) || INTEGER_MIN_VALUE;
        const nB: number = Number.parseFloat(sortB) || INTEGER_MIN_VALUE;
        if (sortDesc) {
          if (nA < nB) return 1;
          if (nA > nB) return -1;
          return 0;
        } else {
          if (nA < nB) return -1;
          if (nA > nB) return 1;
          return 0;
        }
      } else {
        // string
        console.log("string sorting");

        const sA: string = (sortA + "").toLowerCase() || "";
        const sB: string = (sortB + "").toLowerCase() || "";
        if (sortDesc) {
          if (sA < sB) return 1;
          if (sA > sB) return -1;
          return 0;
        } else {
          if (sA < sB) return -1;
          if (sA > sB) return 1;
          return 0;
        }
      }
    });
    this.storeService.set(this.storePrefix + ".list", this.items);
  }

  // pagination
  onItemsPerPageChange() {
    console.log("itemsPerPage: " + this.itemsPerPage);

    this.sortingPagination.count = this.itemsPerPage;

    const newCurrentPage =
      this.pageCount < this.currentPage ? this.pageCount : this.currentPage + 0;

    if (this.currentPage != newCurrentPage) {
      this.currentPage = newCurrentPage;
    } else {
      const searchModel = this.storeService.get(
        this.storePrefix + ".searchModel"
      );
      if (searchModel.count != this.sortingPagination.count) {
        // update searchmodel
        this.storeService.update(
          this.storePrefix + ".searchModel",
          this.sortingPagination
        );
      }
    }
  }

  // pagination
  @Watch("currentPage")
  onCurrentPageChange() {
    console.log("currentPage: " + this.currentPage);

    // correct page number
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }
    this.sortingPagination.offset = this.currentPage - 1;

    const searchModel = this.storeService.get(
      this.storePrefix + ".searchModel"
    );
    if (searchModel.offset != this.sortingPagination.offset) {
      // update searchmodel
      this.storeService.update(
        this.storePrefix + ".searchModel",
        this.sortingPagination
      );
    }
  }

  reload() {
    this.storeService.update(this.storePrefix + ".searchModel", {});
  }
}
