
import Vue from "../../../AppVue";
import Component from "vue-class-component";
import Menu from "../Menu.vue";
import MobileMenu from "../MobileMenu.vue";
import { RouteConfig } from "vue-router";
import Utils from "../../../common/Utils";
import { PropSync } from "vue-property-decorator";
import { MenuItem } from "../../../common/Models";
import routes from "../../../router/Routes";

@Component({
  components: { Menu, MobileMenu },
})
export default class Page extends Vue {
  @PropSync("titleOnPage", { required: false, default: true })
  showTitle!: boolean;
  menuData: MenuItem[] = [];

  created() {
    this.menuData = this.traverseRoutes([], routes, null, this.getRootPath());
  }

  getRootPath() {
    const pathParts = this.$route.path.split("/");
    return pathParts.length < 2 ? "/" : "/" + pathParts[1];
  }

  get menuRootChildren(): MenuItem[] {
    if (this.menuData.length === 1 && this.menuData[0].children) {
      return this.menuData[0].children;
    } else if (this.menuData.length > 1) {
      return this.menuData;
    }
    return [];
  }

  traverseRoutes(
    menuData: MenuItem[],
    routes: RouteConfig[],
    parent: MenuItem | null,
    rootPath?: string
  ): MenuItem[] {
    for (let route of routes) {
      let menuItem: any = {};

      if (!route.meta || !route.meta.forcedIncludingIntoMenu) {
        if (rootPath && !route.path.startsWith(rootPath)) {
          continue;
        }
      }

      if (route.meta) {
        if (route.meta.menuLink) {
          if (route.meta.nameAsLink) {
            (<any>menuItem)["name"] = route.name;
          } else {
            (<any>menuItem)["link"] = route.path;
          }

          if (route.meta.breadcrumb) {
            (<any>menuItem)["title"] = route.meta.breadcrumb[0];
          }

          if (route.meta.isRestricted) {
            (<any>menuItem)["isRestricted"] = route.meta.isRestricted;
            (<any>menuItem)["resource"] = route.meta.resource;
          }
        }
      }

      if (route.children) {
        const menuItemChildren: MenuItem[] = this.traverseRoutes(
          [],
          route.children,
          menuItem
        );
        if (menuItemChildren.length > 0) {
          (<any>menuItem)["children"] = menuItemChildren;
        }
      }

      if (!Utils.isEmptyObject(menuItem)) {
        (<any>menuItem)["parent"] = parent;
        menuData.push(menuItem);
      }
    }
    return menuData;
  }
}
