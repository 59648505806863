
import Vue from "../../AppVue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import MenuListItem from "./menu/MenuListItem.vue";
import { MenuItem } from "../Models";

@Component({
  components: { MenuListItem },
})
export default class MobileMenu extends Vue {
  @Prop() menuItems!: MenuItem[];

  private data: any = {
    showMenu: false,
    visibleMenuItems: [],
    parent: null,
  };

  @Watch("menuItems", { deep: true, immediate: true })
  onChange() {
    this.visibleMenuItems = this.menuItems;
  }

  get visibleMenuItems() {
    return this.data.visibleMenuItems;
  }

  set visibleMenuItems(items: MenuItem[]) {
    this.data.visibleMenuItems = items;
    this.data.parent = items[0].parent;
  }

  closeMenu() {
    if (this.data.showMenu == true) {
      this.data.showMenu = false;
      this.visibleMenuItems = this.menuItems;
    }
  }

  go(item: MenuItem) {
    this.closeMenu();

    if (item.name) {
      this.$router.push({ name: item.name });
    } else {
      this.$router.push({ path: item.link });
    }
  }

  goBack() {
    this.visibleMenuItems = this.data.parent.parent.children;
  }
}
