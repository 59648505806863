
import Vue from "../../../AppVue";
import { ProgressDialogData } from "../../../common/Models";
import StoreService from "../../../common/services/StoreService";
import { STORE_KEYS } from "../../../common/Store";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import Component from "vue-class-component";

@Component({})
export default class ProgressDialog extends Vue {
  data: any = {
    text: "",
    openDialog: false,
  };

  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );

  destroyed() {
    this.storeService.removeWatcher(
      "ProgressDialog",
      STORE_KEYS.DIALOG_PROGRESS
    );
  }

  created() {
    this.storeService.watchUpdate(
      STORE_KEYS.DIALOG_PROGRESS,
      "ProgressDialog",
      (dialogData: ProgressDialogData) => {
        console.log("progress");

        this.data["openDialog"] = dialogData.openDialog;
        this.data["text"] = dialogData.text || "";
      }
    );
  }
}
