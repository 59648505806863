
import Component from "vue-class-component";
import {
  ConfirmDialogData,
  ContextMenuItem,
  OkDialogData,
} from "../../../common/Models";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import StoreService from "../../../common/services/StoreService";
import ItemList from "../../../common/components/ItemList.vue";
import { STORE_KEYS } from "../../../common/Store";
import EditContactDialog from "./EditContactDialog.vue";
import ContactService from "../services/ContactService";
import { Contact, EditContactDialogData } from "../Models";

@Component({
  components: {
    EditContactDialog,
  },
})
export default class ContactList extends ItemList {
  headers = [
    {
      text: "Név",
      align: "left",
      sortable: false,
      value: "name",
      show: true,
    },
    {
      text: "Telefonszám",
      align: "center",
      sortable: false,
      value: "msisdn",
      show: true,
    },
    { text: "", sortable: false, value: "actions", show: true },
  ];
  itemIdToDelete: string | null = null;

  data: any = {};

  contactService: ContactService = container.get<ContactService>(
    SERVICE_IDENTIFIERS.ContactService
  );
  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );

  created() {}

  setContextMenuItems(
    contextMenuItems: ContextMenuItem[],
    contextMenuRowItem: any
  ): void {
    contextMenuItems.push({
      title: "Módosítás",
      icon: "mdiPencil",
      clickFn: () => {
        this.openEditDialog(contextMenuRowItem);
      },
    });
    contextMenuItems.push({
      title: "Törlés",
      icon: "mdiDelete",
      clickFn: () => {
        this.deleteItem(contextMenuRowItem);
      },
    });
  }

  deleteItem(item: Contact) {
    console.log("deleteItem");

    this.itemIdToDelete = item.id + "";

    const confirmDialogData: ConfirmDialogData = {
      ctx: this,
      text: "Biztosan törli a kontaktot?",
      confirmButtonText: "Kontakt törlése",
      clickConfirmedFn: (ctx) => {
        this.loading = true;
        this.contactService
          .deleteContact(this.itemIdToDelete!)
          .then((resp: any) => {
            this.loading = false;
            this.itemIdToDelete = null;
            if (resp != null) {
              this.openOkDialogAfterDeleteSuccess();
            }
          });
      },
    };
    this.storeService.set(STORE_KEYS.DIALOG_CONFIRM, confirmDialogData);
  }

  openEditDialog(item?: Contact) {
    const dialogData: EditContactDialogData = {
      ctx: this,
      editMode: item ? true : false,
      model: item ? item : {},
      afterSubmitFn: (ctx) => {
        ctx.reload();
      },
    };
    this.storeService.set(STORE_KEYS.DIALOG_EDIT_CONTACT, dialogData);
  }

  openOkDialogAfterDeleteSuccess() {
    const okDialogData: OkDialogData = {
      ctx: this,
      text: "Sikeres törlés!",
      afterOkFn: (ctx) => {
        ctx.reload();
      },
    };
    this.storeService.set(STORE_KEYS.DIALOG_OK, okDialogData);
  }
}
