import Vue, { VueConstructor } from "vue";
import { AppAbility } from "./common/services/PermissionServiceImpl";
import Store from "./common/Store";

export interface AppVue extends Vue {
  $ability: AppAbility;
  $icons: { [iconName: string]: string };
  $store: Store;
}

export default Vue as VueConstructor<AppVue>;
