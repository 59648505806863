
import Component from "vue-class-component";
import BreadcrumbsService from "../../../common/services/BreadcrumbsService";
import StoreService from "../../../common/services/StoreService";
import TitleService from "../../../common/services/TitleService";
import Utils from "../../../common/Utils";
import "moment-timezone";
import BackToItemService from "../../../common/services/BackToItemService";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import {
  PAGINATION_ITEMS_PER_PAGE,
  STORE_KEY_SUFFIX_SEARCHMODEL,
} from "../../../config/Constants";
import { STORE_KEYS } from "../../../common/Store";
import { ContactSearchModel } from "../Models";
import Page from "../../../common/components/page/Page.vue";
import ContactService from "../services/ContactService";
import ContactList from "../components/ContactList.vue";

@Component({
  components: {
    Page,
    ContactList,
  },
})
export default class ContactsPage extends Page {
  storePrefix = STORE_KEYS.CONTACTS;
  searchModelStoreKey = this.storePrefix + STORE_KEY_SUFFIX_SEARCHMODEL;
  defaultSorting = "+name";
  loading = false;

  private contactService: ContactService = container.get<ContactService>(
    SERVICE_IDENTIFIERS.ContactService
  );
  private storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );
  private breadcrumbsService: BreadcrumbsService =
    container.get<BreadcrumbsService>(SERVICE_IDENTIFIERS.BreadcrumbsService);
  private titleService: TitleService = container.get<TitleService>(
    SERVICE_IDENTIFIERS.TitleService
  );
  private backToItemService: BackToItemService =
    container.get<BackToItemService>(SERVICE_IDENTIFIERS.BackToItemService);

  destroyed() {
    this.storeService.removeWatcher("ContactsPage", this.searchModelStoreKey);
    this.updateSearchModelWithValidValues();
  }

  updateSearchModelWithValidValues() {
    const lastValidSearchModel = Utils.deepCopy(
      this.storeService.get(this.searchModelStoreKey + ".lastValid")
    );
    const sp = Utils.extractSortingPagination(
      this.storeService.get(this.searchModelStoreKey)
    );
    const sm = Object.assign({}, lastValidSearchModel, sp);
    this.storeService.set(this.searchModelStoreKey, sm, {
      alertWatchers: false,
    });
  }

  created() {
    this.breadcrumbsService.updateBreadcrumbsParams({});

    this.storeService.watchUpdate(
      this.searchModelStoreKey,
      "ContactsPage",
      (searchModel: ContactSearchModel) => {
        this.storeService.set(
          this.searchModelStoreKey + ".lastValid",
          Utils.deepCopy(searchModel)
        );
        this.search(searchModel).then(() => {
          this.backToItemService.backToItem(this);
        });
      }
    );

    // init
    const searchModel: ContactSearchModel =
      this.storeService.get(this.searchModelStoreKey) || {};
    searchModel.offset = this.backToItemService.isActive()
      ? searchModel.offset
      : 0;
    searchModel.sortParams = searchModel.sortParams
      ? searchModel.sortParams
      : this.defaultSorting;
    searchModel.count = searchModel.count
      ? searchModel.count
      : PAGINATION_ITEMS_PER_PAGE;
    this.storeService.set(this.searchModelStoreKey, searchModel);
  }

  get title() {
    return this.titleService.getTitle(this.$route);
  }

  search(searchModel: ContactSearchModel) {
    this.loading = true;

    return this.contactService
      .getContacts(
        searchModel.offset ? searchModel.offset : undefined,
        searchModel.count ? searchModel.count : undefined,
        true
      )
      .then((resp) => {
        this.loading = false;

        this.storeService.set(this.storePrefix + ".total", resp.total);
        this.storeService.set(this.storePrefix + ".list", resp.contacts);
      });
  }
}
