import Store from "../Store";
import { StoreConfig } from "../Models";
export default interface StoreService {
  initStoreFromStorage(): Store;

  get(propName: string): any;

  set(propName: string, propVal: any, config?: StoreConfig): void;

  update(propName: string, newPropVal: any, config?: StoreConfig): void;

  watchUpdate(
    propName: string,
    sourceIdentifier: string,
    fn: Function,
    immediateAlert?: boolean
  ): void;

  removeWatcher(sourceIdentifier: string, propName: string): void;

  hasWatchers(propName: string): boolean;
}
