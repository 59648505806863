import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/common/Models";
import { ApiService, ApiServiceFactory } from "@/common/services/ApiService";
import { API_URL_BASE, CONTACTS_PATH } from "@/config/ApiConfig";
import container from "@/config/InversifyConfig";
import SERVICE_IDENTIFIERS from "@/config/ServiceIdentifiers";
import { injectable } from "inversify";
import { Contact, Contacts } from "../Models";
import ContactService from "./ContactService";

@injectable()
export default class ContactServiceImpl implements ContactService {
  private apiService: ApiService;

  constructor() {
    const apiServiceFactory = container.get<ApiServiceFactory>(
      SERVICE_IDENTIFIERS.ApiServiceFactory
    );
    this.apiService = apiServiceFactory(API_URL_BASE);
  }
  getContacts(
    offset?: number,
    count?: number,
    ignoreCountReached?: boolean
  ): Promise<Contacts> {
    const requestConfig: AxiosRequestConfig = {
      params: {
        offset: offset,
        count: count,
      },
    };
    return this.apiService
      .get<Contacts>(CONTACTS_PATH, requestConfig, ignoreCountReached)
      .then((resp) => {
        const data: Contacts[] = resp.flatMap((r) => r.data);
        return {
          total: data[0].total,
          contacts: data.flatMap((t) => t.contacts) || [],
        };
      });
  }
  postContact(contact: Contact): Promise<Contact> {
    return this.apiService
      .post<Contact>(CONTACTS_PATH, contact)
      .then((resp) => resp.data);
  }
  patchContact(id: string, contact: Contact): Promise<ApiResponse> {
    const url = [CONTACTS_PATH, id].join("/");
    return this.apiService
      .update<ApiResponse>(url, contact)
      .then((resp) => resp.data);
  }
  deleteContact(id: string): Promise<ApiResponse | null> {
    const url = [CONTACTS_PATH, id].join("/");
    const params = {
      connDelete: 0,
    };
    return this.apiService
      .delete<ApiResponse>(url, params)
      .then((resp) => (resp ? resp.data : resp));
  }
}
