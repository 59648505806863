export const PAGINATION_ITEMS_PER_PAGE: number = 50;
export const BREADCRUMBS_PARAMS = "breadcrumbsParams";

export const PHONE_NUMBER_PATTERN =
  "^((?:\\+?3|0)6)(?:-|\\()?(\\d{1,2})(?:-|\\))?(\\d{3})-?(\\d{3,4})$";
export const EMAIL_PATTERN =
  "^[a-zA-Z0-9áéíóöőúüűÁÉÍÓÖŐÚÜŰ.!#$%&'*+/=?^_`{|}~-]+@[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0-9](?:[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0-9-]{0,61}[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0-9])?(?:\\.[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0-9](?:[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0-9-]{0,61}[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0-9])?)*$";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_HU = "YYYY.MM.DD.";
export const TIME_FORMAT = "HH:mm:ss";
export const PAGINATION_TOTAL_VISIBLE = 5;
export const TIMEZONE_BUDAPEST = "Europe/Budapest";

// *** UI paths (in browser) ***
export const UI_URL_BASE = "";
export const UNAUTHORIZED_ACCESS_URL = UI_URL_BASE + "/hozzaferes-megtagadva";
export const LOGIN_UI_URL = UI_URL_BASE + "/belepes";
export const LOGOUT_UI_URL = UI_URL_BASE + "/kilepes";
export const CALLFORWARDINGS_UI_URL = UI_URL_BASE + "/hivasatiranyitasok";
export const CONTACTS_UI_URL = UI_URL_BASE + "/kontaktok";

// *** Sorting ***
export const DEFAULT_TEMPLATES_SORTING: string = "-id";

// *** Store keys ***
export const STORE_KEY_SUFFIX_SEARCHMODEL = ".searchModel";

export const STORE_KEY_USER = "user";
export const STORE_KEY_PREFIX_CFW = "cfw";

export const STORE_KEY_CONFIRM_DIALOG = "dialog.confirm";
export const STORE_KEY_CONTEXT_MENU = "contextMenu";
export const STORE_KEY_OK_DIALOG = "dialog.ok";
export const STORE_KEY_PROGRESS_DIALOG = "dialog.progress";

export const STORE_KEY_RESIZE = "resize";

// *** Validation messages ***
export const VALIDATION_MESSAGE_REQUIRED = `Kötelező mező`;
export const VALIDATION_MESSAGE_SEARCH_TEXT_REQUIRED = `Kötelező mező`;
export const VALIDATION_MESSAGE_MIN_LENGTH = `Nem lehet rövidebb, mint {length} karakter`;
export const VALIDATION_MESSAGE_MAX_LENGTH = `Nem lehet hosszabb, mint {length} karakter`;
export const VALIDATION_MESSAGE_MIN_VALUE = `Nem lehet kisebb, mint {min}`;
export const VALIDATION_MESSAGE_MAX_VALUE = `Nem lehet nagyobb, mint {max}`;
export const VALIDATION_MESSAGE_INTEGER = `A megadott érték nem egész szám`;
export const VALIDATION_MESSAGE_REGEX = `Nem megfelelő formátum`;
export const VALIDATION_MESSAGE_FILE_EXTENSION = `A fájl kiterjesztése nem megfelelő. Engedélyezett: .text, .txt`;

export const INTEGER_MAX_VALUE = Number.MAX_SAFE_INTEGER;
export const INTEGER_MIN_VALUE = Number.MIN_SAFE_INTEGER;

export const BIG_QUERY_LIMIT = 2000;

export const HTTP_STATUS = {
  400: "Hibás lekérés",
  404: "Nem található",
  500: "Belső kiszolgálóhiba",
};

export const CONN_DELETE_ERROR_CODES = {
  "10001": "A törölni kívánt kontakt használatban van.",
};
