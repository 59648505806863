import moment from "moment-timezone";
import {
  CONN_DELETE_ERROR_CODES,
  TIMEZONE_BUDAPEST,
} from "./../../config/Constants";
import { ApiErrorResponse, ApiResponse } from "@/common/Models";
import StoreService from "./StoreService";
import SERVICE_IDENTIFIERS from "../../config/ServiceIdentifiers";
import Utils from "../Utils";
import saveAs from "file-saver";
import UserService from "./UserService";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { inject, injectable } from "inversify";

export type ApiServiceFactory = (baseUrl: string) => ApiService;

@injectable()
export class ApiService {
  private axios: AxiosInstance;

  @inject(SERVICE_IDENTIFIERS.StoreService)
  private storeService!: StoreService;

  @inject(SERVICE_IDENTIFIERS.UserService)
  private userService!: UserService;

  constructor() {
    /* this.axios = axios.create({
      baseURL: `${location.protocol}//${location.hostname}:${PORT}${baseUrl}`,
    }); */
    this.axios = axios.create();

    this.setDefaultHeaders();
    this.setInterceptors();
  }

  public init(baseURL: string): void {
    this.axios.defaults.baseURL = baseURL;
  }

  private setInterceptors(): void {
    this.axios.interceptors.request.use(
      function(config: any) {
        config.headers["timestamp"] = moment.tz(TIMEZONE_BUDAPEST).format();
        return config;
      },
      function(error: any) {
        return Promise.reject(error);
      }
    );

    const responseInterceptor: (
      response: AxiosResponse<any>
    ) => AxiosResponse<any> = (resp) => {
      // do nothing
      return resp;
    };
    const rejectRespInterceptor: (error: any) => any = (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      if (
        error.response &&
        error.response.status &&
        error.response.status === 403
      ) {
        // user has to re-login (user has no session cookie or has expired session cookie)
        this.userService.setUser(null);
        const vue: Vue = this.storeService.get("vue");
        if (vue) {
          vue.$router.push({ name: "login" });
        }

        return;
      } else {
        return Promise.reject(error);
      }
    };

    this.axios.interceptors.response.use(
      responseInterceptor,
      rejectRespInterceptor
    );
  }

  private setDefaultHeaders(): void {
    this.axios.defaults.headers.common["Accept"] = "application/json";
    this.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  }

  setHeader(headerName: string, headerValue: any) {
    this.axios.defaults.headers.common[headerName] = headerValue;
  }

  private alertError(resp: AxiosResponse) {
    const data = resp.data;
    if (data.errorCode) {
      const errorResp: ApiResponse = {
        code: data.errorCode,
        detail: data.errorDescription,
        status: resp.status,
      };

      this.storeService.set("error", errorResp, { alertWatchers: true });
    }
  }

  alertConnDeleteError(apiErrorResp: ApiErrorResponse): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (apiErrorResp.errorCode) {
        const errorData: ApiResponse = {
          code: apiErrorResp.errorCode,
          detail: (<any>CONN_DELETE_ERROR_CODES)[apiErrorResp.errorCode],
        };
        this.storeService.set("errorConfirm", errorData, {
          alertWatchers: true,
        });

        this.storeService.watchUpdate(
          "errorConfirm.value",
          "ApiService",
          (confirmed: boolean) => {
            this.storeService.removeWatcher("ApiService", "errorConfirm.value");
            resolve(confirmed);
          }
        );
      } else {
        resolve(false);
      }
    });
  }

  private checkAttributeInConfig(
    attrName: string,
    config?: AxiosRequestConfig
  ): AxiosRequestConfig {
    if (!config) {
      config = {};
    }

    if (Utils.isUndefined((<any>config)[attrName])) {
      (<any>config)[attrName] = {};
    }

    return config;
  }

  async get<T>(
    url: string,
    config?: AxiosRequestConfig,
    ignoreCountReached?: boolean,
    ignoreErrorStatus?: number[] // reason: no display 404 error
  ): Promise<AxiosResponse<T>[]> {
    config = this.checkAttributeInConfig("params", config);
    const params = config["params"];
    let countReached: boolean = true;
    let respArray: AxiosResponse<T>[] = [];
    while (countReached) {
      try {
        const resp: AxiosResponse<T> = await this.axios.get(url, config);

        if (!resp) {
          break;
        }
        respArray.push(resp);

        countReached =
          resp.headers["count-reached"] &&
          resp.headers["count-reached"] === "true";

        if (
          countReached &&
          !Utils.isUndefined(params["offset"]) &&
          !ignoreCountReached
        ) {
          params["offset"] += 1;
        } else {
          break;
        }
      } catch (error) {
        const errorResp: AxiosResponse = (error as any).response;
        if (ignoreErrorStatus) {
          const errorStatus = errorResp.status;
          if (!ignoreErrorStatus.includes(errorStatus)) {
            this.alertError(errorResp);
          }
        } else {
          this.alertError(errorResp);
        }
        throw new Error(`[CFW] ApiService ${errorResp}`);
      }
    }
    return new Promise<AxiosResponse<T>[]>((resolve) => resolve(respArray));
  }

  post<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axios.post(url, data, config).catch((error) => {
      this.alertError(error.response);

      throw new Error(`[CFW] ApiService ${error.response}`);
    });
  }

  put<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axios.put(url, data, config).catch((error) => {
      this.alertError(error.response);

      throw new Error(`[CFW] ApiService ${error.response}`);
    });
  }

  update<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axios.patch(url, data, config).catch((error) => {
      this.alertError(error.response);

      throw new Error(`[CFW] ApiService ${error.response}`);
    });
  }

  delete<T>(url: string, params?: any): Promise<AxiosResponse<T> | null> {
    const config = { params: params };
    return this.axios.delete(url, config).catch((error) => {
      const errorResp: AxiosResponse = error.response;
      const apiErrorResp: ApiErrorResponse = errorResp.data;

      if (
        apiErrorResp.errorCode &&
        (<any>CONN_DELETE_ERROR_CODES)[apiErrorResp.errorCode]
      ) {
        return this.alertConnDeleteError(apiErrorResp).then((confirmed) => {
          if (confirmed) {
            const p = {
              ...params,
              connDelete: 1,
            };
            return this.delete(url, p);
          } else {
            return new Promise<any>((resolve) => {
              resolve(null);
            });
          }
        });
      } else {
        this.alertError(errorResp);
        throw new Error(`[CFW] ApiService ${error.response}`);
      }
    });
  }

  downloadFile(url: string, params?: any, headers?: any): Promise<void> {
    const config: AxiosRequestConfig = {
      params: { ...params, offset: 0 },
      headers: { ...headers },
      responseType: "blob",
      timeout: 30000,
    };
    return this.axios
      .get(url, config)
      .then((resp) => {
        if (resp.headers["content-disposition"]) {
          let filename = (resp.headers["content-disposition"] || ("" as string))
            .split(";")
            .find((n: string) => n.includes("filename="))
            .replace(/^(.*filename=){1}"/g, "")
            .replace(/["]*/g, "");
          const blob = new Blob([resp.data], {
            type: resp.headers["content-type"],
          });

          saveAs(blob, filename);
        }
      })
      .catch((error) => {
        this.alertError(error.response);

        throw new Error(`[CFW] ApiService ${error.response}`);
      });
  }
}
