import { Ability, AnyAbility } from "@casl/ability";
import { User } from "@/common/Models";

export default interface PermissionService {
  defineRulesFor(user: User | null): any;

  buildAbilityFor(user: User | null): Ability;

  createAbilityWithNoRules(): Ability;

  updateAbility(user: User | null): void;

  getAbility(): AnyAbility;
}
