import { Container, interfaces } from "inversify";
import "reflect-metadata";
import SERVICE_IDENTIFIERS from "./ServiceIdentifiers";
import { ApiService } from "@/common/services/ApiService";
import StoreService from "../common/services/StoreService";
import StoreServiceImpl from "../common/services/StoreServiceImpl";
import BreadcrumbsService from "../common/services/BreadcrumbsService";
import BreadcrumbsServiceImpl from "../common/services/BreadcrumbsServiceImpl";
import TitleService from "../common/services/TitleService";
import TitleServiceImpl from "../common/services/TitleServiceImpl";
import BackToItemService from "../common/services/BackToItemService";
import BackToItemServiceImpl from "../common/services/BackToItemServiceImpl";
import UserService from "../common/services/UserService";
import UserServiceImpl from "../common/services/UserServiceImpl";
import ProgressService from "../common/services/ProgressService";
import ProgressServiceImpl from "../common/services/ProgressServiceImpl";
import PermissionService from "../common/services/PermissionService";
import PermissionServiceImpl from "../common/services/PermissionServiceImpl";
import AuthService from "@/modules/auth/services/AuthService";
import AuthServiceImpl from "@/modules/auth/services/AuthServiceImpl";
import Store from "@/common/Store";
import AppStatusService from "@/modules/api-clients/services/AppStatusService";
import AppStatusServiceImpl from "@/modules/api-clients/services/AppStatusServiceImpl";
import ApiClientService from "@/modules/api-clients/services/ApiClientService";
import ApiClientServiceImpl from "@/modules/api-clients/services/ApiClientServiceImpl";
import CallForwardingService from "@/modules/api-clients/services/CallForwardingService";
import CallForwardingServiceImpl from "@/modules/api-clients/services/CallForwardingServiceImpl";
import ContactService from "@/modules/contacts/services/ContactService";
import ContactServiceImpl from "@/modules/contacts/services/ContactServiceImpl";

const container = new Container();

container
  .bind<Store>(SERVICE_IDENTIFIERS.Store)
  .to(Store)
  .inSingletonScope();
container
  .bind<StoreService>(SERVICE_IDENTIFIERS.StoreService)
  .to(StoreServiceImpl)
  .inSingletonScope();
container
  .bind<BreadcrumbsService>(SERVICE_IDENTIFIERS.BreadcrumbsService)
  .to(BreadcrumbsServiceImpl)
  .inSingletonScope();
container
  .bind<TitleService>(SERVICE_IDENTIFIERS.TitleService)
  .to(TitleServiceImpl)
  .inSingletonScope();
container
  .bind<BackToItemService>(SERVICE_IDENTIFIERS.BackToItemService)
  .to(BackToItemServiceImpl)
  .inSingletonScope();
container
  .bind<UserService>(SERVICE_IDENTIFIERS.UserService)
  .to(UserServiceImpl)
  .inSingletonScope();
container
  .bind<ProgressService>(SERVICE_IDENTIFIERS.ProgressService)
  .to(ProgressServiceImpl)
  .inSingletonScope();
container
  .bind<PermissionService>(SERVICE_IDENTIFIERS.PermissionService)
  .to(PermissionServiceImpl)
  .inSingletonScope();
container
  .bind<AuthService>(SERVICE_IDENTIFIERS.AuthService)
  .to(AuthServiceImpl)
  .inSingletonScope();
container
  .bind<ApiClientService>(SERVICE_IDENTIFIERS.ApiClientService)
  .to(ApiClientServiceImpl)
  .inSingletonScope();
container
  .bind<CallForwardingService>(SERVICE_IDENTIFIERS.CallForwardingService)
  .to(CallForwardingServiceImpl)
  .inSingletonScope();
container
  .bind<ContactService>(SERVICE_IDENTIFIERS.ContactService)
  .to(ContactServiceImpl)
  .inSingletonScope();
container.bind<ApiService>(SERVICE_IDENTIFIERS.ApiService).to(ApiService);
container
  .bind<interfaces.Factory<ApiService>>(SERVICE_IDENTIFIERS.ApiServiceFactory)
  .toFactory((context: interfaces.Context) => {
    return (baseUrl: string) => {
      const apiService = context.container.get<ApiService>(
        SERVICE_IDENTIFIERS.ApiService
      );
      apiService.init(baseUrl);
      return apiService;
    };
  });
container
  .bind<AppStatusService>(SERVICE_IDENTIFIERS.AppStatusService)
  .to(AppStatusServiceImpl)
  .inSingletonScope();

export default container;
