
import Vue from "../../../AppVue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Utils from "../../../common/Utils";
import { MenuItem } from "../../../common/Models";

@Component({
  components: {},
})
export default class MenuListItem extends Vue {
  @Prop() item!: MenuItem;
  @Prop() level!: number;

  get marginLeft(): string {
    return 20 + 20 * this.level + "px";
  }

  isGroup() {
    return !Utils.isUndefined(this.item.children);
  }

  goTo(path: string) {
    this.$router.push({ path: path });
  }
}
