
import Component from "vue-class-component";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import { required, regex, max } from "vee-validate/dist/rules";
import {
  DATE_FORMAT_HU,
  TIMEZONE_BUDAPEST,
  TIME_FORMAT,
  VALIDATION_MESSAGE_MAX_LENGTH,
  VALIDATION_MESSAGE_REGEX,
  VALIDATION_MESSAGE_REQUIRED,
} from "../../../config/Constants";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import CallForwardingService from "../services/CallForwardingService";
import { STORE_KEYS } from "../../../common/Store";
import { CallForwarding, EditCallForwardingDialogData } from "../Models";
import FormPage from "../../../common/components/page/FormPage.vue";
import StoreService from "../../../common/services/StoreService";
import BackToItemService from "../../../common/services/BackToItemService";
import Utils from "../../../common/Utils";
import DateTimePicker from "../../../common/components/DateTimePicker.vue";
import moment from "moment-timezone";
import { Contact } from "../../../modules/contacts/Models";

extend("required", required);
extend("regex", regex);
extend("max", max);

localize({
  hu: {
    messages: {
      required: VALIDATION_MESSAGE_REQUIRED,
      max: VALIDATION_MESSAGE_MAX_LENGTH,
      regex: VALIDATION_MESSAGE_REGEX,
    },
    fields: {},
  },
});
localize("hu");

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    DateTimePicker,
  },
})
export default class EditCallForwardingDialog extends FormPage {
  data: any = {
    openDialog: false,
    dialogData: {
      editMode: false,
    },
    loading: false,
  };
  dateTimeFormat = DATE_FORMAT_HU + " " + TIME_FORMAT;

  callForwardingService: CallForwardingService =
    container.get<CallForwardingService>(
      SERVICE_IDENTIFIERS.CallForwardingService
    );
  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );
  backToItemService: BackToItemService = container.get<BackToItemService>(
    SERVICE_IDENTIFIERS.BackToItemService
  );

  destroyed() {
    this.storeService.removeWatcher(
      "EditCallForwardingDialog",
      STORE_KEYS.DIALOG_EDIT_CALLFORWARDING
    );

    this.storeService.removeWatcher("EditCallForwardingDialog", "error");
  }

  created() {
    this.initModels();

    this.storeService.watchUpdate(
      STORE_KEYS.DIALOG_EDIT_CALLFORWARDING,
      "EditCallForwardingDialog",
      (dialogData: EditCallForwardingDialogData) => {
        this.initModels();
        this.queryData();

        this.setSubmitted(false);
        this.data["dialogData"] = { ...dialogData };
        if (this.data.dialogData.model.validFromTs) {
          this.data.dialogData.model.validFromTs = moment(
            this.data.dialogData.model.validFromTs
          ).format(this.dateTimeFormat);
        }

        this.model = {
          ...this.model,
          ...Utils.deepCopy(this.data.dialogData.model),
        };

        this.oldModel = Utils.deepCopy(this.model);

        this.data["openDialog"] = true;
      },
      true
    );

    // if error occures while submitting, set loading's status to false
    this.storeService.watchUpdate(
      "error",
      "EditCallForwardingDialog",
      (data: any) => {
        if (this.data.loading) {
          this.data.loading = false;
        }
      }
    );
  }

  initModels() {
    this.data.loading = false;
    this.model = {};
  }

  queryData() {
    if (!this.data.contacts) {
      this.data.contacts =
        this.storeService.get(STORE_KEYS.CFW + ".contacts") || [];
    }
  }

  emptyUpdate() {
    const updateObj: CallForwarding = this.getUpdateObj(
      this.oldModel,
      this.model
    );
    return Utils.isEmptyObject(updateObj);
  }

  getUpdateObj(
    oldModel: CallForwarding,
    currentModel: CallForwarding
  ): CallForwarding {
    const updateObj: CallForwarding = Utils.modelChangedProps(
      oldModel,
      currentModel
    );

    return updateObj;
  }

  formatDateTime(dt: string | undefined | null) {
    if (!dt || dt.length === 0) return "";

    return moment(dt).tz(TIMEZONE_BUDAPEST).format();
  }

  save() {
    this.validateForm().then((success: boolean) => {
      if (!success) {
        return;
      }
      this.data.loading = true;

      if (!this.data.dialogData.editMode) {
        // create mode

        let updateObj: CallForwarding = this.getUpdateObj({}, this.model);
        updateObj.apiClientId = this.model.apiClientId;

        if (updateObj.validFromTs) {
          updateObj.validFromTs = this.formatDateTime(
            updateObj.validFromTs.toString()
          );
        }

        this.callForwardingService
          .postCallForwarding(updateObj)
          .then((callForwarding) => {
            this.backToItemService.setItem(callForwarding.apiClientId + "");
            this.finishEditing();
          });
      } else {
        // edit mode

        let updateObj: CallForwarding = this.getUpdateObj(
          this.oldModel,
          this.model
        );
        updateObj.apiClientId = this.model.apiClientId;

        if (Utils.isDeletedFromObject(this.oldModel, this.model, "contactId")) {
          updateObj.contactId = -1;
        }

        if (updateObj.validFromTs) {
          updateObj.validFromTs = this.formatDateTime(
            updateObj.validFromTs.toString()
          );
        }

        this.callForwardingService
          .patchCallForwarding(this.model.id, updateObj)
          .then((resp) => {
            this.backToItemService.setItem(this.model.id + "");
            this.finishEditing();
          });
      }
    });
  }

  cancel() {
    this.clearErrors(true);
    this.setSubmitted(true);

    if (this.data.dialogData.afterCancelFn) {
      this.data.dialogData.afterCancelFn(this.data.dialogData.ctx);
    }

    this.closeDialog();
  }

  finishEditing() {
    this.data.loading = false;
    this.setSubmitted(true);

    if (this.data.dialogData.afterSubmitFn) {
      this.data.dialogData.afterSubmitFn(this.data.dialogData.ctx);
    }

    this.closeDialog();
  }

  closeDialog() {
    this.$set(this.data, "openDialog", false);
    this.$set(this.data, "dialogData", {
      editMode: false,
    });

    this.storeService.set(STORE_KEYS.DIALOG_EDIT_CALLFORWARDING, null, {
      alertWatchers: false,
    });
  }

  getContacts() {
    if (this.data.contacts) {
      return (this.data.contacts as Contact[]).map((contact) => {
        return {
          text: `${contact.name} (+36${contact.msisdn})`,
          value: contact.id,
        };
      });
    }
    return [];
  }
}
