import TitleService from "./TitleService";
import { Route } from "vue-router";
import { injectable } from "inversify";

@injectable()
export default class TitleServiceImpl implements TitleService {
  getTitle(route: Route): string {
    return route.meta ? route.meta["title"] : "";
  }
}
