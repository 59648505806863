
import Vue from "../../../AppVue";
import Component from "vue-class-component";
import { ApiResponse } from "../../Models";
import Utils from "../../../common/Utils";
import StoreService from "../../../common/services/StoreService";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import { HTTP_STATUS } from "../../../config/Constants";

@Component({})
export default class ErrorDialog extends Vue {
  model: any = {
    open: false,
    title: "",
    text: "",
  };

  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );

  created() {
    this.storeService.watchUpdate(
      "error",
      ErrorDialog.name,
      (errorResp: ApiResponse) => {
        const title = this.getStatus(errorResp.status);
        const text = errorResp.code == "500" ? title : errorResp.detail;
        this.$set(this.model, "title", title);
        this.$set(this.model, "text", text);
        this.$set(this.model, "open", true);
      }
    );
  }

  reset() {
    this.$set(this.model, "title", "");
    this.$set(this.model, "text", "");
    this.$set(this.model, "open", false);
  }

  getStatus(statusCode: number | undefined): string {
    const statusStr = "Hiba";
    if (Utils.isUndefined(statusCode)) {
      return statusStr;
    }
    return (<any>HTTP_STATUS)[statusCode!] || statusStr;
  }

  destroyed() {
    this.storeService.removeWatcher(ErrorDialog.name, "error");
  }
}
