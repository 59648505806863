var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMenu),expression:"closeMenu"}],staticClass:"container-adaptive d-flex flex-column justify-start",staticStyle:{"width":"100%"}},[_c('div',{style:(`${_vm.showMenu ? 'border-bottom:1px black solid' : ''}`)},[_c('div',{staticClass:"py-3 d-flex flex-wrap align-center",style:(_vm.$vuetify.breakpoint.mobile
            ? `min-height:56px;margin-left:-3px!important`
            : `min-height:64px;margin-left:-3px!important`)},[(_vm.showTitle)?_c('div',{staticClass:"mr-3"},[_c('span',{staticClass:"px-3 d-inline-block telenor-bold text-uppercase align-center",staticStyle:{"font-size":"21px"}},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c('div',[_vm._l((_vm.menuItems),function(menuItem){return [(
                menuItem.isRestricted ? _vm.$can('read', menuItem.resource) : true
              )?[(menuItem.children)?_c('v-btn',{key:menuItem.title,class:_vm.isHighlightedMenu(menuItem.title)
                    ? 'mr-5 highlighted'
                    : 'mr-5',attrs:{"color":_vm.isHighlightedMenu(menuItem.title) ? '#e20074' : '',"ripple":false,"text":""},on:{"click":function($event){$event.stopPropagation();_vm.highlightMenuItem(menuItem.title);
                  _vm.menuItemChildren = menuItem.children;}}},[_c('span',{staticStyle:{"font-size":"21px"}},[_vm._v(_vm._s(menuItem.title))])]):_c('v-btn',{key:menuItem.title,staticClass:"mr-5",attrs:{"ripple":false,"text":""},on:{"click":function($event){return _vm.go(menuItem)}}},[_c('span',{staticStyle:{"font-size":"21px"}},[_vm._v(_vm._s(menuItem.title))])])]:_vm._e()]})],2),_c('div',{staticClass:"ml-auto"})])]),(_vm.showMenu)?_c('div',{staticStyle:{"width":"100% !important"}},[_c('div',{staticClass:"white elevation-1",staticStyle:{"left":"0 !important","position":"absolute !important","z-index":"10","width":"100%"}},[_c('v-container',{staticClass:"d-flex justify-center white py-0",staticStyle:{"border-top":"1px black solid"},attrs:{"fluid":""}},[_c('div',{staticClass:"container-adaptive"},[_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{attrs:{"flat":"","close-on-content-click":false}},[_c('v-card-title',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeMenu}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.mdiClose))])],1)],1),_c('v-card-text',[_c('div',{staticClass:"d-flex flex-wrap justify-start align-center"},[_c('v-row',[_vm._l((_vm.menuItemChildren),function(menuItem){return [(
                            menuItem.isRestricted
                              ? _vm.$can('read', menuItem.resource)
                              : true
                          )?[_c('v-col',{key:menuItem.title,attrs:{"xl":"3","lg":"3","md":"3","sm":"6","xs":"12"}},[(!menuItem.children)?[_c('v-btn',{key:menuItem.title,staticClass:"menu-btn",attrs:{"link":"","ripple":false,"text":""},on:{"click":function($event){return _vm.go(menuItem)}}},[_c('span',[_vm._v(_vm._s(menuItem.title))])])]:[_c('v-list',{key:menuItem.title,attrs:{"dense":""}},[_c('v-subheader',[_c('span',{staticClass:"telenor-bold"},[_vm._v(_vm._s(menuItem.title))])]),_c('v-list-item-group',_vm._l((menuItem.children),function(subMenuItem){return _c('v-list-item',{key:subMenuItem.title,staticClass:"pl-0",staticStyle:{"margin-left":"-8px"},attrs:{"ripple":false,"inactive":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-btn',{staticClass:"menu-btn",attrs:{"ripple":false,"link":"","text":""},on:{"click":function($event){return _vm.go(subMenuItem)}}},[_c('span',[_vm._v(_vm._s(subMenuItem.title))])])],1)],1)],1)}),1)],1)]],2)]:_vm._e()]})],2)],1)])],1)],1)])])],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }