
import Component from "vue-class-component";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import { required, regex, max } from "vee-validate/dist/rules";
import {
  VALIDATION_MESSAGE_MAX_LENGTH,
  VALIDATION_MESSAGE_REGEX,
  VALIDATION_MESSAGE_REQUIRED,
} from "../../../config/Constants";
import ApiClientService from "../services/ApiClientService";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import CallForwardingService from "../services/CallForwardingService";
import { STORE_KEYS } from "../../../common/Store";
import {
  ApiClient,
  CallForwarding,
  EditApiClientDialogData,
  EditApiClientDialogDataModel,
} from "../Models";
import FormPage from "../../../common/components/page/FormPage.vue";
import StoreService from "../../../common/services/StoreService";
import BackToItemService from "../../../common/services/BackToItemService";
import Utils from "../../../common/Utils";
import { Contact } from "../../../modules/contacts/Models";

extend("required", required);
extend("regex", regex);
extend("max", max);

localize({
  hu: {
    messages: {
      required: VALIDATION_MESSAGE_REQUIRED,
      max: VALIDATION_MESSAGE_MAX_LENGTH,
      regex: VALIDATION_MESSAGE_REGEX,
    },
    fields: {},
  },
});
localize("hu");

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class EditApiClientDialog extends FormPage {
  data: any = {
    openDialog: false,
    dialogData: {
      editMode: false,
    },
    loading: false,
  };

  apiClientService: ApiClientService = container.get<ApiClientService>(
    SERVICE_IDENTIFIERS.ApiClientService
  );
  callForwardingService: CallForwardingService =
    container.get<CallForwardingService>(
      SERVICE_IDENTIFIERS.CallForwardingService
    );
  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );
  backToItemService: BackToItemService = container.get<BackToItemService>(
    SERVICE_IDENTIFIERS.BackToItemService
  );

  destroyed() {
    this.storeService.removeWatcher(
      "EditApiClientDialog",
      STORE_KEYS.DIALOG_EDIT_API_CLIENT
    );

    this.storeService.removeWatcher("EditApiClientDialog", "error");
  }

  created() {
    this.initModels();

    this.data.apiKeys =
      this.storeService.get(STORE_KEYS.CFW + ".apiKeys") || [];

    this.storeService.watchUpdate(
      STORE_KEYS.DIALOG_EDIT_API_CLIENT,
      "EditApiClientDialog",
      (dialogData: EditApiClientDialogData) => {
        this.initModels();
        this.queryData();

        this.setSubmitted(false);
        this.data["dialogData"] = dialogData;

        this.model = {
          ...this.model,
          ...Utils.deepCopy(this.data.dialogData.model),
        };

        this.oldModel = Utils.deepCopy(this.model);

        this.data["openDialog"] = true;
        this.$nextTick(() => {
          if (this.data.dialogData.editMode) {
            Utils.focusToFormField(document, "#clientId", 0);
          } else {
            Utils.focusToFormField(document, "#apiKey", 0);
          }
        });
      },
      true
    );

    // if error occures while submitting, set loading's status to false
    this.storeService.watchUpdate(
      "error",
      "EditApiClientDialog",
      (data: any) => {
        if (this.data.loading) {
          this.data.loading = false;
        }
      }
    );
  }

  initModels() {
    this.data.loading = false;
    this.model = {
      apiClient: {},
      currentCallForwarding: {},
    };
  }

  queryData() {
    if (!this.data.apiKeys) {
      this.data.apiKeys =
        this.storeService.get(STORE_KEYS.CFW + ".apiKeys") || [];
    }
    if (!this.data.contacts) {
      this.data.contacts =
        this.storeService.get(STORE_KEYS.CFW + ".contacts") || [];
    }
  }

  emptyUpdate() {
    const updateObj: EditApiClientDialogDataModel = this.getUpdateObj(
      this.oldModel,
      this.model
    );
    return Utils.isEmptyObject(updateObj);
  }

  getUpdateObj(
    oldModel: EditApiClientDialogDataModel,
    currentModel: EditApiClientDialogDataModel
  ): EditApiClientDialogDataModel {
    const updateObj: EditApiClientDialogDataModel = Utils.modelChangedProps(
      oldModel,
      currentModel
    );

    return updateObj;
  }

  createCallForwardingSubmitObject(
    callForwarding?: CallForwarding
  ): CallForwarding {
    if (callForwarding && callForwarding.contactId) {
      return callForwarding;
    } else {
      return {
        contactId: -1,
      };
    }
  }

  save() {
    this.validateForm().then((success: boolean) => {
      if (!success) {
        return;
      }
      this.data.loading = true;
      console.log("save");

      if (!this.data.dialogData.editMode) {
        // create mode

        let updateObj: EditApiClientDialogDataModel = this.getUpdateObj(
          {},
          this.model
        );

        // post api client
        const apiClientToCreate: ApiClient = { ...updateObj }.apiClient!;
        this.apiClientService
          .postApiClient(apiClientToCreate)
          .then((apiClient) => {
            // post call forwarding
            updateObj.currentCallForwarding = {
              ...updateObj.currentCallForwarding,
              apiClientId: apiClient.id,
            };
            this.submitCallForwarding(updateObj.currentCallForwarding!);
          });
      } else {
        // edit mode

        let updateObj: EditApiClientDialogDataModel = this.getUpdateObj(
          this.oldModel,
          this.model
        );

        const apiClientToUpdate: ApiClient = { ...updateObj }.apiClient!;

        new Promise((resolve) => {
          if (!Utils.isEmptyObject(apiClientToUpdate)) {
            // patch api client
            this.apiClientService
              .patchApiClient(this.model.apiClient.id, apiClientToUpdate)
              .then((resp) => resolve(resp));
          } else {
            // no api client to update
            resolve(null);
          }
        }).then((resp) => {
          if (updateObj.currentCallForwarding) {
            // post call forwarding
            updateObj.currentCallForwarding = {
              ...updateObj.currentCallForwarding,
              apiClientId: this.model.apiClient.id,
            };
            this.submitCallForwarding(updateObj.currentCallForwarding);
          } else {
            this.backToItemService.setItem(this.model.apiClient.id + "");
            this.finishEditing();
          }
        });
      }
    });
  }

  submitCallForwarding(callForwarding: CallForwarding) {
    this.callForwardingService
      .postCallForwarding(callForwarding)
      .then((callForwarding) => {
        this.backToItemService.setItem(callForwarding.apiClientId + "");
        this.finishEditing();
      });
  }

  cancel() {
    this.clearErrors(true);
    this.setSubmitted(true);

    if (this.data.dialogData.afterCancelFn) {
      this.data.dialogData.afterCancelFn(this.data.dialogData.ctx);
    }

    this.closeDialog();
  }

  finishEditing() {
    this.data.loading = false;
    this.setSubmitted(true);

    if (this.data.dialogData.afterSubmitFn) {
      this.data.dialogData.afterSubmitFn(this.data.dialogData.ctx);
    }

    this.closeDialog();
  }

  closeDialog() {
    this.$set(this.data, "openDialog", false);
    this.$set(this.data, "dialogData", {
      editMode: false,
    });

    this.storeService.set(STORE_KEYS.DIALOG_EDIT_API_CLIENT, null, {
      alertWatchers: false,
    });
  }

  getContacts() {
    if (this.data.contacts) {
      return (this.data.contacts as Contact[]).map((contact) => {
        return {
          text: `${contact.name} (+36${contact.msisdn})`,
          value: contact.id,
        };
      });
    }
    return [];
  }
}
