// for Inversify
const SERVICE_IDENTIFIERS = {
  Store: Symbol.for("Store"),
  StoreService: Symbol.for("StoreService"),
  BreadcrumbsService: Symbol.for("BreadcrumbsService"),
  TitleService: Symbol.for("TitleService"),
  BackToItemService: Symbol.for("BackToItemService"),
  UserService: Symbol.for("UserService"),
  ProgressService: Symbol.for("ProgressService"),
  PermissionService: Symbol.for("PermissionService"),
  ConstantsService: Symbol.for("ConstantsService"),
  AuthService: Symbol.for("AuthService"),
  ApiClientService: Symbol.for("ApiClientService"),
  CallForwardingService: Symbol.for("CallForwardingService"),
  ApiService: Symbol.for("ApiService"),
  ApiServiceFactory: Symbol.for("ApiServiceFactory"),
  ContactService: Symbol.for("ContactService"),
  AppStatusService: Symbol.for("AppStatusService"),
};
export default SERVICE_IDENTIFIERS;
