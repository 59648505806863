import { injectable } from "inversify";
import { Watcher } from "./Models";
import Utils from "./Utils";

export enum STORE_KEYS {
  USER = "USER",
  JSESSIONID = "JSESSIONID",
  CONTEXT_MENU = "CONTEXT_MENU",
  DIALOG_CONFIRM = "DIALOG_CONFIRM",
  DIALOG_OK = "DIALOG_OK",
  DIALOG_PROGRESS = "DIALOG_PROGRESS",
  RESIZE = "RESIZE",
  CFW = "CFW",
  DIALOG_EDIT_API_CLIENT = "DIALOG_EDIT_API_CLIENT",
  DIALOG_EDIT_CALLFORWARDING = "DIALOG_EDIT_CALLFORWARDING",
  CONTACTS = "CONTACTS",
  DIALOG_EDIT_CONTACT = "DIALOG_EDIT_CONTACT",
  APP_STATUS = "APP_STATUS",
}

@injectable()
export default class Store {
  private state: any = {};

  private watchers: { [sourceId: string]: Watcher[] } = {};

  get(propName: string): any {
    if (!Object.keys(this.state).includes(propName)) {
      console.log(
        `There is no stored value associated with key: '${propName}'`
      );
      return null;
    }
    return this.state[propName];
  }

  set(propName: string, propVal: any, alertWatchers?: boolean): void {
    this.state[propName] = propVal;

    if (alertWatchers === false) {
      return;
    }

    Object.values(this.watchers)
      .flat()
      .filter((watcher: Watcher) => watcher.propName == propName)
      .forEach((watcher: Watcher) => watcher.updateFn(propVal));
  }

  update(propName: string, newPropVal: any, alertWatchers?: boolean): void {
    const storedVal = this.get(propName);

    if (storedVal == null) {
      this.set(propName, newPropVal, alertWatchers);
    } else {
      const newVal = Object.assign({}, storedVal, newPropVal);
      this.set(propName, newVal, alertWatchers);
    }
  }

  watchUpdate(
    propName: string,
    sourceIdentifier: string,
    fn: Function,
    immediateAlert?: boolean
  ): void {
    if (!Object.keys(this.watchers).includes(sourceIdentifier)) {
      this.watchers[sourceIdentifier] = [];
    }
    const watchers = this.watchers[sourceIdentifier].filter(
      (watcher) => watcher.propName == propName
    );
    if (watchers.length == 0) {
      const newWatcher: Watcher = {
        propName: propName,
        source: sourceIdentifier,
        updateFn: fn,
      };
      this.watchers[sourceIdentifier].push(newWatcher);
      console.log(
        `Added new watcher for this source and propName: ${sourceIdentifier} -> ${propName}`
      );

      const propVal = this.get(propName);
      if (propVal && immediateAlert) {
        newWatcher.updateFn(propVal);
      }
    }
  }

  removeWatcher(sourceIdentifier: string, propName: string) {
    const watchers = this.watchers[sourceIdentifier];
    if (Utils.isUndefined(watchers)) {
      console.log(
        `There is no watcher to delete with this source and propName: ${sourceIdentifier} -> ${propName}`
      );
      return;
    }

    let idx = -1;
    for (let i = 0; i < watchers.length; ++i) {
      const watcher = watchers[i];
      if (watcher.propName == propName) {
        idx = i;
        break;
      }
    }

    if (idx == -1) {
      console.log(
        `There is no watcher to delete with this source and propName: ${sourceIdentifier} -> ${propName}`
      );
      return;
    }

    watchers.splice(idx, 1);
  }

  hasWatchers(propName: string): boolean {
    const watcherCount = Object.values(this.watchers)
      .flat()
      .filter((watcher: Watcher) => watcher.propName == propName).length;
    return watcherCount > 0 ? true : false;
  }
}
