import {
  CALLFORWARDINGS_UI_URL,
  CONTACTS_UI_URL,
  LOGIN_UI_URL,
  LOGOUT_UI_URL,
  UNAUTHORIZED_ACCESS_URL,
} from "./../config/Constants";
import { RouteConfig } from "vue-router";
import checkAuth from "@/middlewares/checkAuth";
import CallForwardingsPage from "../modules/api-clients/views/CallForwardingsPage.vue";
import ContactsPage from "../modules/contacts/views/ContactsPage.vue";

const routes: RouteConfig[] = [
  {
    path: "/",
    redirect: CALLFORWARDINGS_UI_URL,
  },
  {
    path: CALLFORWARDINGS_UI_URL,
    name: "callForwardings",
    beforeEnter: checkAuth,
    component: CallForwardingsPage,
    meta: {
      breadcrumb: ["Hívásátirányítások"],
      title: "Hívásátirányítások",
      forcedIncludingIntoMenu: true,
      linkable: true,
      menuLink: true,
      resource: "CallForwardings",
      isRestricted: true,
    },
  },
  {
    path: CONTACTS_UI_URL,
    name: "contacts",
    beforeEnter: checkAuth,
    component: ContactsPage,
    meta: {
      breadcrumb: ["Kontaktok"],
      title: "Kontaktok",
      forcedIncludingIntoMenu: true,
      linkable: true,
      menuLink: true,
      resource: "Contacts",
      isRestricted: true,
    },
  },
  {
    path: LOGIN_UI_URL,
    name: "login",
    beforeEnter: checkAuth,
    component: () => import("../modules/auth/views/LoginPage.vue"),
  },
  {
    path: LOGOUT_UI_URL,
    name: "logout",
    beforeEnter: checkAuth,
    component: () => import("../modules/auth/views/LogoutPage.vue"),
    meta: {
      isRestricted: true,
    },
  },
  {
    path: UNAUTHORIZED_ACCESS_URL,
    name: "unauthorized",
    beforeEnter: checkAuth,
    component: () => import("../modules/auth/views/UnauthorizedPage.vue"),
  },
  { path: "*", redirect: "/" },
];
export default routes;
