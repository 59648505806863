export interface ApiResponse {
  status?: number;
  detail?: string;
  code?: string;
}

export interface ApiErrorResponse {
  errorCode?: string;
  errorDescription?: string;
}

export interface SortingPagination {
  offset?: number;
  count?: number;
  sortParams?: string;
  fields?: string;
}

export interface Breadcrumb {
  text: string;
  disabled: boolean;
  href?: string;
}

export interface Watcher {
  source: string;
  propName: string;
  updateFn: Function;
}

export enum PropChangeType {
  REMOVED = "REMOVED",
  ADDED = "ADDED",
  CHANGED = "CHANGED",
  NOT_CHANGED = "NOT_CHANGED",
  AUTO_INSERTED = "AUTO_INSERTED",
  IGNORED = "IGNORED",
}

export enum UserRole {
  USER = "User",
  GUEST = "Guest",
}

export interface User {
  roles: UserRole[];
  userId?: string;
}

export interface MenuItem {
  title: string;
  link?: string;
  name?: string;
  children?: MenuItem[];
  parent?: MenuItem;
  isRestricted?: boolean;
  resource?: string;
}

export enum TrueFalse {
  TRUE = "Igen",
  FALSE = "Nem",
}

export default interface CustomContextMenuIf {
  onContextMenuRowItemChanged(
    evt: MouseEvent,
    rowItem: any,
    menuPosX: number,
    menuPosY: number
  ): void;

  setContextMenuItems(
    contextMenuItems: ContextMenuItem[],
    contextMenuRowItem: any
  ): void;
}

export interface ContextMenuItem {
  title: string;
  icon?: string;
  clickFn(ctx: any): any;
}

export interface ContextMenuData {
  ctx: any;
  menuItems: ContextMenuItem[];
  positionX: number;
  positionY: number;
}

export interface ConfirmDialogData {
  ctx: any;
  text: string;
  confirmButtonText: string;
  clickConfirmedFn(ctx: any): any;
  clickCancelFn?(ctx: any): any;
  maxWidth?: number;
}

export interface OkDialogData {
  ctx: any;
  text: string;
  afterOkFn?(ctx: any): any;
}

export interface InfoDialogData extends OkDialogData {
  data: any;
}

export interface ProgressDialogData {
  openDialog: boolean;
  text?: string;
}

export interface WindowSize {
  width: number;
  height: number;
}

export interface StoreConfig {
  alertWatchers?: boolean;
  persisted?: boolean;
}
