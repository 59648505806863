import { AxiosRequestConfig } from "axios";
import { ApiService, ApiServiceFactory } from "@/common/services/ApiService";
import { API_URL_BASE } from "@/config/ApiConfig";
import { inject, injectable } from "inversify";
import { ApiResponse, UserRole } from "../../../common/Models";
import AuthService from "./AuthService";
import UserService from "@/common/services/UserService";
import SERVICE_IDENTIFIERS from "@/config/ServiceIdentifiers";
import container from "@/config/InversifyConfig";

@injectable()
export default class AuthServiceImpl implements AuthService {
  @inject(SERVICE_IDENTIFIERS.UserService)
  private userService!: UserService;

  private apiService: ApiService;

  constructor() {
    const apiServiceFactory = container.get<ApiServiceFactory>(
      SERVICE_IDENTIFIERS.ApiServiceFactory
    );

    this.apiService = apiServiceFactory(API_URL_BASE);
  }

  login(username: string, password: string): Promise<boolean> {
    // basic auth
    const base64Encoded = window.btoa(username + ":" + password);
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Basic " + base64Encoded,
      },
    };

    return this.apiService
      .post<ApiResponse>("/user/login", {}, requestConfig)
      .then((resp) => {
        if (resp.data.status === 200) {
          console.log("login success");

          this.userService.setUser({
            roles: [UserRole.USER],
          });

          return true;
        }
        return false;
      });
  }

  logout(): Promise<boolean> {
    return this.apiService.post<ApiResponse>("/logout", {}).then((resp) => {
      if (resp.data.status === 200) {
        this.userService.setUser(null);
        return true;
      }
      return false;
    });
  }
}
