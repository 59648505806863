
import Component from "vue-class-component";
import { AppStatus, AppStatusInfo, CallForwarding } from "../Models";
import Utils from "../../../common/Utils";
import { DATE_FORMAT_HU, TIME_FORMAT } from "../../../config/Constants";
import { Contact } from "../../../modules/contacts/Models";
import AppVue from "../../../AppVue";
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ExpandedApps extends AppVue {
  @Prop() appStatuses!: AppStatusInfo;
  @Prop() contacts!: Contact[];

  formatDateTime(dt: string) {
    return Utils.formatDateTime(dt, DATE_FORMAT_HU + " " + TIME_FORMAT);
  }

  isForwarded(callForwarding: CallForwarding) {
    return callForwarding && callForwarding.contactId;
  }

  showContact(contactId: number) {
    if (this.contacts) {
      const contact: Contact | undefined = this.contacts.find(
        (contact) => contact.id === contactId
      );
      if (contact) {
        return `${contact.name} (+36${contact.msisdn})`;
      }
    }
    return "ismeretlen kontakt";
  }

  getConnectedDevices(): AppStatus[] {
    return Utils.sortStringValueObjArray(
      Object.values(this.appStatuses || {}),
      "deviceInfo"
    );
  }
}
