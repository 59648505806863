
import Component from "vue-class-component";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import { required, regex, max } from "vee-validate/dist/rules";
import {
  VALIDATION_MESSAGE_MAX_LENGTH,
  VALIDATION_MESSAGE_REGEX,
  VALIDATION_MESSAGE_REQUIRED,
} from "../../../config/Constants";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import { STORE_KEYS } from "../../../common/Store";
import { Contact, EditContactDialogData } from "../Models";
import FormPage from "../../../common/components/page/FormPage.vue";
import StoreService from "../../../common/services/StoreService";
import BackToItemService from "../../../common/services/BackToItemService";
import Utils from "../../../common/Utils";
import ContactService from "../services/ContactService";

extend("required", required);
extend("regex", regex);
extend("max", max);

localize({
  hu: {
    messages: {
      required: VALIDATION_MESSAGE_REQUIRED,
      max: VALIDATION_MESSAGE_MAX_LENGTH,
      regex: VALIDATION_MESSAGE_REGEX,
    },
    fields: {},
  },
});
localize("hu");

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class EditContactDialog extends FormPage {
  data: any = {
    openDialog: false,
    dialogData: {
      editMode: false,
    },
    loading: false,
  };

  contactService: ContactService = container.get<ContactService>(
    SERVICE_IDENTIFIERS.ContactService
  );
  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );
  backToItemService: BackToItemService = container.get<BackToItemService>(
    SERVICE_IDENTIFIERS.BackToItemService
  );

  destroyed() {
    this.storeService.removeWatcher(
      "EditContactDialog",
      STORE_KEYS.DIALOG_EDIT_CONTACT
    );

    this.storeService.removeWatcher("EditContactDialog", "error");
  }

  created() {
    this.initModels();

    this.storeService.watchUpdate(
      STORE_KEYS.DIALOG_EDIT_CONTACT,
      "EditContactDialog",
      (dialogData: EditContactDialogData) => {
        this.initModels();

        this.setSubmitted(false);
        this.data["dialogData"] = dialogData;

        this.model = {
          ...this.model,
          ...Utils.deepCopy(this.data.dialogData.model),
        };

        this.oldModel = Utils.deepCopy(this.model);

        this.data["openDialog"] = true;
        this.$nextTick(() => {
          Utils.focusToFormField(document, "#name", 0);
        });
      },
      true
    );

    // if error occures while submitting, set loading's status to false
    this.storeService.watchUpdate("error", "EditContactDialog", (data: any) => {
      if (this.data.loading) {
        this.data.loading = false;
      }
    });
  }

  initModels() {
    this.data = {
      openDialog: false,
      dialogData: {
        editMode: false,
      },
      loading: false,
    };
    this.model = {};
  }

  emptyUpdate() {
    const updateObj: Contact = this.getUpdateObj(this.oldModel, this.model);
    return Utils.isEmptyObject(updateObj);
  }

  getUpdateObj(oldModel: Contact, currentModel: Contact): Contact {
    const updateObj: Contact = Utils.modelChangedProps(oldModel, currentModel);

    return updateObj;
  }

  save() {
    this.validateForm().then((success: boolean) => {
      if (!success) {
        return;
      }
      this.data.loading = true;

      if (!this.data.dialogData.editMode) {
        // create mode

        let updateObj: Contact = this.getUpdateObj({}, this.model);
        this.contactService.postContact(updateObj).then((contact) => {
          this.finishEditing();
        });
      } else {
        // edit mode

        let updateObj: Contact = this.getUpdateObj(this.oldModel, this.model);
        this.contactService
          .patchContact(this.model.id, updateObj)
          .then((resp) => {
            this.backToItemService.setItem(this.model.id + "");
            this.finishEditing();
          });
      }
    });
  }

  cancel() {
    this.clearErrors(true);
    this.setSubmitted(true);

    if (this.data.dialogData.afterCancelFn) {
      this.data.dialogData.afterCancelFn(this.data.dialogData.ctx);
    }

    this.closeDialog();
  }

  finishEditing() {
    this.data.loading = false;
    this.setSubmitted(true);

    if (this.data.dialogData.afterSubmitFn) {
      this.data.dialogData.afterSubmitFn(this.data.dialogData.ctx);
    }

    this.closeDialog();
  }

  closeDialog() {
    this.$set(this.data, "openDialog", false);
    this.$set(this.data, "dialogData", {
      editMode: false,
    });

    this.storeService.set(STORE_KEYS.DIALOG_EDIT_CONTACT, null, {
      alertWatchers: false,
    });
  }
}
