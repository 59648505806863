
import Vue from "../../AppVue";
import Component from "vue-class-component";
import moment, { Moment } from "moment-timezone";
import TimeSelector from "vue-timeselector";
import { Emit, Prop, Watch } from "vue-property-decorator";
import {
  DATE_FORMAT,
  DATE_FORMAT_HU,
  TIMEZONE_BUDAPEST,
  TIME_FORMAT,
} from "../../config/Constants";
import { mdiCalendar } from "@mdi/js";
import Utils from "../Utils";

interface DisabledTimes {
  h: number[] | null;
  m: number[] | null;
  s: number[] | null;
}
const noDisabledTimes: DisabledTimes = {
  h: null,
  m: null,
  s: null,
};
enum UP_DOWN {
  UP,
  DOWN,
}

@Component({
  components: {
    TimeSelector,
  },
})
export default class DateTimePicker extends Vue {
  @Prop({ default: true }) showSeconds!: boolean;
  @Prop() displayFormat!: string;
  @Prop() returnFormat!: string;
  @Prop() value!: string | undefined;
  @Prop() min!: string;
  @Prop() max!: string;

  data: any = {
    open: false,
  };

  get iconCalendar() {
    return mdiCalendar;
  }

  @Emit("input") emitInput(): string {
    return this.dateTimeToReturn;
  }

  @Emit("closed") emitClosed(): void {}

  @Watch("value", { immediate: true })
  onValueChange() {
    console.log(this.value);

    let valueMoment: Moment = moment(
      this.value || "",
      this.dateTimeToReturnFormat
    );

    if (this.minDateTime && valueMoment.isBefore(this.minDateTime)) {
      valueMoment = moment(this.minDateTime);
    } else if (this.maxDateTime && valueMoment.isAfter(this.maxDateTime)) {
      valueMoment = moment(this.maxDateTime);
    }

    if (valueMoment.isValid()) {
      this.valueDate = valueMoment.format(DATE_FORMAT);
      this.valueTime = valueMoment.toDate();
    } else {
      this.valueDate = "";
      this.valueTime = null;
    }
  }

  get minDateTime(): Moment | null {
    return this.min ? moment(this.min) : null;
  }

  get maxDateTime(): Moment | null {
    return this.max ? moment(this.max) : null;
  }

  get minDate(): string {
    return this.minDateTime ? this.minDateTime.format(DATE_FORMAT) : "";
  }

  get maxDate(): string {
    return this.maxDateTime ? this.maxDateTime.format(DATE_FORMAT) : "";
  }

  get valueDate(): string {
    return this.data["valueDate"];
  }

  set valueDate(value: string) {
    this.$set(this.data, "valueDate", value);
    // to update picker UI
    this.submit();
  }

  get valueTime(): Date | null {
    return this.data["valueTime"] || null;
  }

  set valueTime(value: Date | null) {
    this.$set(this.data, "valueTime", value);
    this.submit();
  }

  get timeFormat(): string {
    return this.showSeconds ? TIME_FORMAT : TIME_FORMAT.replace(":ss", "");
  }

  get timeSelectorInterval() {
    let obj: { h: number; m: number; s?: number } = {
      h: 1,
      m: 1,
    };
    if (this.showSeconds) {
      obj = {
        ...obj,
        s: 1,
      };
    }
    return obj;
  }

  get dateTimeToShowFormat(): string {
    return this.displayFormat
      ? this.displayFormat
      : `${DATE_FORMAT_HU} ${this.timeFormat}`;
  }

  get dateTimeToShow(): string {
    return this.valuePicked.isValid()
      ? this.valuePicked.format(this.dateTimeToShowFormat)
      : "";
  }

  get dateTimeToReturnFormat(): string {
    return this.returnFormat
      ? this.returnFormat
      : `${DATE_FORMAT} ${this.timeFormat}`;
  }

  get dateTimeToReturn(): string {
    return this.valuePicked.isValid()
      ? this.valuePicked.format(this.dateTimeToReturnFormat)
      : "";
  }

  get valuePicked(): Moment {
    return this.data["valuePicked"] || moment("");
  }

  set valuePicked(value: Moment) {
    this.$set(this.data, "valuePicked", value);
  }

  get disabledTimes(): DisabledTimes {
    let disabledTimes: DisabledTimes = { ...noDisabledTimes };

    if (!this.valuePicked || !this.valuePicked.isValid()) return disabledTimes;

    if (this.minDateTime) {
      disabledTimes = this.minDisabledTimes;
    }

    if (this.maxDateTime) {
      disabledTimes = this.mergeTimes(disabledTimes, this.maxDisabledTimes);
    }

    console.log(disabledTimes);

    return disabledTimes;
  }

  mergeTimes(t1: DisabledTimes, t2: DisabledTimes): DisabledTimes {
    if (!t2) return t1;
    return {
      h: this.mergeArrays(t1.h, t2.h),
      m: this.mergeArrays(t1.m, t2.m),
      s: this.mergeArrays(t1.s, t2.s),
    };
  }

  mergeArrays(ar1: number[] | null, ar2: number[] | null): number[] {
    return (ar1 || []).concat(
      (ar2 || []).filter((i) => (ar1 || []).indexOf(i) < 0)
    );
  }

  range(from: number, to: number) {
    let a: number[] = [];
    for (let i = from; i < to; ++i) {
      a.push(i);
    }
    return a;
  }

  getMinTime(dt: Moment, min: Moment): Moment {
    const dtm = moment(min).startOf("second");

    if (!Utils.areSame(dt, min, "minute")) {
      dtm.set({ second: 0 });
      if (!Utils.areSame(dt, min, "hour")) {
        dtm.set({ minute: 0 });
        if (!Utils.areSame(dt, min, "day")) {
          dtm.set({ hour: 0 });
        }
      }
    }

    // change dt (valueTimeMoment) if it's too early
    if (dt.isBefore(min)) {
      dt.set({
        hour: dtm.get("h"),
        minute: dtm.get("m"),
        second: dtm.get("s"),
      });
    }

    return dtm;
  }

  get minDisabledTimes(): DisabledTimes {
    if (!this.minDateTime) return noDisabledTimes;
    const minTime = this.getMinTime(this.valueTimeMoment, this.minDateTime);
    return {
      h: this.range(0, minTime.get("h")),
      m: this.range(0, minTime.get("m")),
      s: this.range(0, minTime.get("s")),
    };
  }

  getMaxTime(dt: Moment, max: Moment): Moment {
    let dtm = moment(max).startOf("second");

    if (!Utils.areSame(dt, max, "minute")) {
      dtm.set({ second: 59 });
      if (!Utils.areSame(dt, max, "hour")) {
        dtm.set({ minute: 59 });
        if (!Utils.areSame(dt, max, "day")) {
          dtm.set({ hour: 23 });
        }
      }
    }

    dtm.set({
      hour: Math.min(dtm.get("h") + 1, 23),
      minute: Math.min(dtm.get("m") + 1, 59),
      second: Math.min(dtm.get("s") + 1, 59),
    });

    return dtm;
  }

  get maxDisabledTimes(): DisabledTimes {
    if (!this.maxDateTime) return noDisabledTimes;
    const maxTime = this.getMaxTime(this.valueTimeMoment, this.maxDateTime);
    return {
      h: this.range(maxTime.get("h"), 24),
      m: this.range(maxTime.get("m"), 60),
      s: this.range(maxTime.get("s"), 60),
    };
  }

  get valueTimeMoment(): Moment {
    return this.valueTime
      ? moment(this.valueTime.toISOString()).tz(TIMEZONE_BUDAPEST)
      : moment().startOf("day");
  }

  submit() {
    const time: string = this.valueTimeMoment.format(this.timeFormat);
    this.valuePicked = moment(`${this.valueDate} ${time}`);
    this.emitInput();
  }

  ok() {
    this.submit();
    this.data.open = false;
    this.emitClosed();
  }

  clear() {
    this.valuePicked = moment("");
    this.emitInput();
  }

  destroyed() {
    this.value = undefined;
  }
}
