import { UserRole } from "@/common/Models";
import { injectable } from "inversify";
import { User } from "@/common/Models";
import PermissionService from "./PermissionService";

import {
  AbilityBuilder,
  Ability,
  AbilityClass,
  AnyAbility,
} from "@casl/ability";
import Vue from "@/AppVue";
import AppVue from "@/AppVue";

type Actions = "manage" | "create" | "read" | "update" | "delete";
type Subjects = "all" | "CallForwardings";

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

@injectable()
export default class PermissionServiceImpl implements PermissionService {
  private ability!: AnyAbility;

  getAbility(): AnyAbility {
    return this.ability;
  }

  updateAbility(user: User | null): void {
    const rules = this.defineRulesFor(user);
    console.log(rules);
    this.ability = new AppVue().$ability.update(rules);
  }

  defineRulesFor(user: User | null): any {
    const { can, cannot, rules } = new AbilityBuilder(AppAbility);

    if (!user) {
      return [UserRole.GUEST];
    }

    if (user && user.roles && user.roles.includes(UserRole.USER)) {
      can("manage", "all");
    } else {
      cannot("manage", "all");
    }

    return rules;
  }

  buildAbilityFor(user: User | null): Ability {
    return new AppAbility(this.defineRulesFor(user));
  }

  createAbilityWithNoRules(): Ability {
    return this.buildAbilityFor({ roles: [UserRole.GUEST] });
  }
}
