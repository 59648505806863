import {
  API_URL_BASE,
  CALL_FORWARDINGS_PATH,
} from "./../../../config/ApiConfig";
import { ApiService, ApiServiceFactory } from "@/common/services/ApiService";
import container from "@/config/InversifyConfig";
import SERVICE_IDENTIFIERS from "@/config/ServiceIdentifiers";
import { AxiosRequestConfig } from "axios";
import { injectable } from "inversify";
import { CallForwarding } from "../Models";
import CallForwardingService from "./CallForwardingService";
import { ApiResponse } from "@/common/Models";

@injectable()
export default class CallForwardingServiceImpl
  implements CallForwardingService {
  private apiService: ApiService;

  constructor() {
    const apiServiceFactory = container.get<ApiServiceFactory>(
      SERVICE_IDENTIFIERS.ApiServiceFactory
    );
    this.apiService = apiServiceFactory(API_URL_BASE);
  }

  getLatestCallForwarding(
    apiKey: string,
    clientId: string
  ): Promise<CallForwarding> {
    const requestConfig: AxiosRequestConfig = {
      headers: {
        apikey: apiKey,
        "client-id": clientId,
      },
    };
    return this.apiService
      .get<CallForwarding>(CALL_FORWARDINGS_PATH, requestConfig, true)
      .then((resp) => resp[0].data);
  }

  postCallForwarding(callForwarding: CallForwarding): Promise<CallForwarding> {
    return this.apiService
      .post<CallForwarding>(CALL_FORWARDINGS_PATH, callForwarding)
      .then((resp) => resp.data);
  }

  patchCallForwarding(
    id: string,
    callForwarding: CallForwarding
  ): Promise<ApiResponse> {
    const url = [CALL_FORWARDINGS_PATH, id].join("/");
    return this.apiService
      .update<ApiResponse>(url, callForwarding)
      .then((resp) => resp.data);
  }

  deleteCallForwarding(id: string): Promise<ApiResponse | null> {
    const url = [CALL_FORWARDINGS_PATH, id].join("/");
    return this.apiService
      .delete<ApiResponse>(url)
      .then((resp) => (resp ? resp.data : resp));
  }
}
