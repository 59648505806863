
import Vue from "./AppVue";
import Component from "vue-class-component";
import ErrorDialog from "./common/components/dialogs/ErrorDialog.vue";
import ErrorConfirmDialog from "./common/components/dialogs/ErrorConfirmDialog.vue";
import Utils from "./common/Utils";
import OkDialog from "./common/components/dialogs/OkDialog.vue";
import SERVICE_IDENTIFIERS from "./config/ServiceIdentifiers";
import { STORE_KEY_RESIZE } from "./config/Constants";
import StoreService from "./common/services/StoreService";
import ContextMenu from "./common/components/ContextMenu.vue";
import { WindowSize } from "./common/Models";
import ConfirmDialog from "./common/components/dialogs/ConfirmDialog.vue";
import container from "./config/InversifyConfig";
import UserService from "./common/services/UserService";
import ProgressDialog from "./common/components/dialogs/ProgressDialog.vue";

@Component({
  components: {
    ErrorDialog,
    ErrorConfirmDialog,
    ContextMenu,
    OkDialog,
    ConfirmDialog,
    ProgressDialog,
  },
})
export default class App extends Vue {
  originalAppBarHeight!: number | undefined;
  minAppBarHeight: number = 5;
  stickyToolbar: boolean = false;

  data: any = {
    logoMarginLeft: 0,
  };

  storeService = container.get<StoreService>(SERVICE_IDENTIFIERS.StoreService);
  userService = container.get<UserService>(SERVICE_IDENTIFIERS.UserService);

  created() {
    // disable console log on production
    if (Utils.isProductionEnv()) {
      console.log = () => {};
    }
  }

  mounted() {
    this.originalAppBarHeight = this.getOriginalAppBarHeight();
  }

  loadImg(v: any) {
    // adjust logo location
    const logoRef: any = this.$refs.logoRef;
    if (logoRef) {
      const width: number = (<any>logoRef)["image"]["width"];
      this.$set(this.data, "logoMarginLeft", width ? -width / 4 : 0);
    }
  }

  isLoggedIn(): boolean {
    return !!this.userService.getUser();
  }

  onScroll(e: any) {
    if (this.$vuetify.breakpoint.mdAndUp && this.originalAppBarHeight) {
      const newMarginTop = Math.max(
        -window.scrollY,
        this.minAppBarHeight - this.originalAppBarHeight
      );

      // set sticky toolbar
      if (
        !this.stickyToolbar &&
        window.scrollY > this.originalAppBarHeight - this.minAppBarHeight
      ) {
        // appBar has "min height"

        this.setStickyMenu(true);
        this.stickyToolbar = true;
      } else if (
        this.stickyToolbar &&
        window.scrollY <= this.originalAppBarHeight - this.minAppBarHeight
      ) {
        this.resetStickyToolbar();
      }

      if (!this.stickyToolbar) {
        (<any>this.$refs.appBar)["styles"]["margin-top"] = newMarginTop + "px";
      }
    } else {
      this.resetStickyToolbar();
    }
  }

  onResize() {
    const windowSize: WindowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.storeService.set(STORE_KEY_RESIZE, windowSize, {
      alertWatchers: true,
    });
  }

  resetStickyToolbar() {
    this.setStickyMenu(false);
    this.stickyToolbar = false;
  }

  setStickyMenu(sticky: boolean): void {
    const elem = this.$el.querySelector("#appMenu");
    const logoHeader = this.$el.querySelector("#logoHeader");
    if (elem) {
      if (sticky) {
        elem.classList.add("sticky-element");
        elem.classList.add("elevation-1");
        if (logoHeader) {
          logoHeader.classList.add("hidden");
        }
      } else {
        elem.classList.remove("sticky-element");
        elem.classList.remove("elevation-1");
        if (logoHeader) {
          logoHeader.classList.remove("hidden");
        }
      }
    }
  }

  getOriginalAppBarHeight(): number | undefined {
    const appBar: any = this.$refs.appBar;
    const height: string = appBar["styles"]["height"];
    if (height) {
      const heightNum = Number.parseInt(height.replace("px", ""));
      return heightNum;
    }
    return undefined;
  }
}
