
import Vue from "../../../AppVue";
import { MenuItem } from "../../../common/Models";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MenuListItemGroup extends Vue {
  @Prop() item!: MenuItem;
  @Prop() level!: number;

  created() {
    this.level += 1;
  }
}
