
import Component from "vue-class-component";
import { ConfirmDialogData, OkDialogData } from "../../../common/Models";
import ApiClientService from "../services/ApiClientService";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import CallForwardingService from "../services/CallForwardingService";
import {
  CallForwarding,
  EditCallForwardingDialogData,
  ExpandedView,
} from "../Models";
import StoreService from "../../../common/services/StoreService";
import { STORE_KEYS } from "../../../common/Store";
import Utils from "../../../common/Utils";
import {
  DATE_FORMAT_HU,
  TIMEZONE_BUDAPEST,
  TIME_FORMAT,
} from "../../../config/Constants";
import moment from "moment-timezone";
import EditCallForwardingDialog from "./EditCallForwardingDialog.vue";
import { Contact } from "../../../modules/contacts/Models";
import AppVue from "../../../AppVue";
import { Emit, Prop } from "vue-property-decorator";

@Component({
  components: {
    EditCallForwardingDialog,
  },
})
export default class ExpandedCallForwardings extends AppVue {
  @Prop() futureCallForwardings!: CallForwarding[];
  @Prop() contacts!: Contact[];
  @Emit("close") close() {}
  @Emit("reload") reload() {}

  itemIdToDelete: string | null = null;

  apiClientService: ApiClientService = container.get<ApiClientService>(
    SERVICE_IDENTIFIERS.ApiClientService
  );
  callForwardingService: CallForwardingService =
    container.get<CallForwardingService>(
      SERVICE_IDENTIFIERS.CallForwardingService
    );
  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );

  openEditCallForwardingDialog(item: CallForwarding) {
    const editMode: boolean = !!!Utils.isEmptyObject(
      Utils.modelChangedProps({}, item, undefined, undefined, ["apiClientId"])
    );

    const nowPlusOneMin = moment.tz(TIMEZONE_BUDAPEST).add(1, "m").format();

    const dialogData: EditCallForwardingDialogData = {
      ctx: this,
      editMode: editMode,
      model: item,
      validFromStart: nowPlusOneMin,
      afterSubmitFn: (ctx) => {
        ctx.reload();
      },
    };
    this.storeService.set(STORE_KEYS.DIALOG_EDIT_CALLFORWARDING, dialogData);
  }

  deleteCallForwarding(item: CallForwarding) {
    console.log("delete callForwarding");

    this.itemIdToDelete = item.id + "";

    const confirmDialogData: ConfirmDialogData = {
      ctx: this,
      text: "Biztosan törli az átirányítást?",
      confirmButtonText: "Átirányítás törlése",
      clickConfirmedFn: (ctx) => {
        this.callForwardingService
          .deleteCallForwarding(this.itemIdToDelete!)
          .then((resp: any) => {
            this.itemIdToDelete = null;
            this.openOkDialogAfterDeleteSuccess();
          });
      },
    };
    this.storeService.set(STORE_KEYS.DIALOG_CONFIRM, confirmDialogData);
  }

  openOkDialogAfterDeleteSuccess() {
    const okDialogData: OkDialogData = {
      ctx: this,
      text: "Sikeres törlés!",
      afterOkFn: (ctx) => {
        ctx.reload();
        // close expanded item row if apiclient has no remained callforwarding
        // not re-queried apiclient list after delete yet so remained one (already deleted) cf in list
        if (this.futureCallForwardings.length === 1) {
          console.log("close expanded callforwardings row");
          this.close();
        }
      },
    };
    this.storeService.set(STORE_KEYS.DIALOG_OK, okDialogData);
  }

  formatDateTime(dt: string) {
    return Utils.formatDateTime(dt, DATE_FORMAT_HU + " " + TIME_FORMAT);
  }

  isForwarded(callForwarding: CallForwarding) {
    return callForwarding && callForwarding.contactId;
  }

  showContact(contactId: number) {
    if (this.contacts) {
      const contact: Contact | undefined = this.contacts.find(
        (contact) => contact.id === contactId
      );
      if (contact) {
        return `${contact.name} (+36${contact.msisdn})`;
      }
    }
    return "ismeretlen kontakt";
  }
}
