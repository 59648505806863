import BackToItemService from "./BackToItemService";
import StoreService from "./StoreService";
import SERVICE_IDENTIFIERS from "../../config/ServiceIdentifiers";
import scrollIntoView from "scroll-into-view";
import Utils from "../Utils";
import { inject, injectable } from "inversify";
import Vue from "*.vue";

@injectable()
export default class BackToItemServiceImpl implements BackToItemService {
  private storeKey = "backToItem";

  @inject(SERVICE_IDENTIFIERS.StoreService)
  private storeService!: StoreService;

  isActive(): boolean {
    return this.storeService.get(this.storeKey) != null;
  }

  setItem(htmlQuerySelector: string): void {
    this.storeService.set(this.storeKey, "#id-" + htmlQuerySelector);
  }

  setItems(htmlQuerySelectors: string[]): void {
    const selectors = htmlQuerySelectors.map((s) => `#id-${s}`);
    this.storeService.set(this.storeKey, selectors);
  }

  backToItem(ctx: Vue): void {
    const backToItem = this.storeService.get(this.storeKey);

    if (Utils.isArray(backToItem)) {
      let isFirst = true;
      (backToItem as string[]).forEach((i) => {
        const el: HTMLElement | null = ctx.$el.querySelector(i);
        if (el) {
          ctx.$nextTick(() => {
            if (isFirst) {
              isFirst = false;
              scrollIntoView(el);
            }
            this.highlightRow(el);
          });
        }
      });
    } else {
      const el: HTMLElement | null = ctx.$el.querySelector(backToItem);
      if (el) {
        ctx.$nextTick(() => {
          scrollIntoView(el);
          this.highlightRow(el);
        });
      }
    }
    this.storeService.set(this.storeKey, null);
  }

  private highlightRow(el: HTMLElement): void {
    const parentRow = el.parentElement!.parentElement;
    if (parentRow) {
      parentRow.classList.add("blue");
      setTimeout(() => {
        parentRow.classList.remove("blue");
      }, 2000);
    }
  }
}
