import { ApiService, ApiServiceFactory } from "@/common/services/ApiService";
import StoreService from "@/common/services/StoreService";
import { STORE_KEYS } from "@/common/Store";
import { API_URL_BASE, APP_STATUSES_PATH } from "@/config/ApiConfig";
import container from "@/config/InversifyConfig";
import SERVICE_IDENTIFIERS from "@/config/ServiceIdentifiers";
import { injectable } from "inversify";
import { AppStatus, AppStatusInfo } from "../Models";
import AppStatusService from "./AppStatusService";

@injectable()
export default class AppStatusServiceImpl implements AppStatusService {
  private apiService: ApiService;

  constructor() {
    const apiServiceFactory = container.get<ApiServiceFactory>(
      SERVICE_IDENTIFIERS.ApiServiceFactory
    );
    this.apiService = apiServiceFactory(API_URL_BASE);
  }

  private sse: EventSource | null = null;

  private storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );

  subscribe(): void {
    this.sse = new EventSource(APP_STATUSES_PATH + "/subscribe");

    this.sse.onmessage = (evt: MessageEvent) => {
      if (evt.data.event && evt.data.event === "close") {
        this.sse!.close();
        this.storeService.set(STORE_KEYS.APP_STATUS, {});
        return;
      }

      const data: AppStatusInfo = JSON.parse(evt.data);
      const stored: AppStatusInfo = {
        ...this.storeService.get(STORE_KEYS.APP_STATUS),
      };
      Object.entries(data).forEach((e) => {
        const appIdentifier: string = e[0];
        const appStatus: AppStatus = e[1];

        if (!appStatus.lastHeartBeatTime) {
          // delete form store
          delete stored[appIdentifier];
        } else {
          stored[appIdentifier] = appStatus;
        }
      });

      this.storeService.set(STORE_KEYS.APP_STATUS, stored);
    };
  }

  unsubscribe(): void {
    if (!this.sse) return;
    this.apiService.get<void>(APP_STATUSES_PATH + "/unsubscribe");
  }
}
