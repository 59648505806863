import { BREADCRUMBS_PARAMS } from "../../config/Constants";
import { Breadcrumb } from "../Models";
import { Route } from "vue-router";
import BreadcrumbsService from "./BreadcrumbsService";
import { inject, injectable } from "inversify";
import StoreService from "./StoreService";
import SERVICE_IDENTIFIERS from "../../config/ServiceIdentifiers";
import Utils from "../Utils";

@injectable()
export default class BreadcrumbsServiceImpl implements BreadcrumbsService {
  private homePage: Breadcrumb = {
    text: "Főoldal",
    disabled: false,
    href: "/",
  };

  @inject(SERVICE_IDENTIFIERS.StoreService)
  private storeService!: StoreService;

  createBreadcrumbs(route: Route): Breadcrumb[] {
    let breadcrumbs: Breadcrumb[] = [];

    const breadcrumbsParams: any = this.storeService.get(BREADCRUMBS_PARAMS);
    if (Object.keys(breadcrumbsParams).length === 0) {
      console.log("No breadcrumbs params");
    }

    let fullPath: string = route.fullPath;
    // remove first and (if exists) last "/" path element
    const urlBase = fullPath.substring(
      1,
      fullPath.substring(1).indexOf("/") + 1
    );
    const urlBaseLength = urlBase.length;
    if (fullPath.endsWith("/")) {
      fullPath = fullPath.substring(urlBaseLength + 1, fullPath.length - 1);
    } else {
      fullPath = fullPath.substring(urlBaseLength + 1);
    }

    const paths: string[] = fullPath.split("/");
    const metas: any[] = route.matched.map((r) => r.meta);

    let link = "/" + urlBase;
    for (let i = 0; i < paths.length; ++i) {
      let path = paths[i];
      const meta = metas[i];
      const isLast = i == paths.length - 1;

      const breadcrumb: any = {};

      if (Utils.isUndefined(meta["breadcrumb"])) {
        continue;
      }
      const mb: string = (meta["breadcrumb"] + "").replace(":", "");

      const isParam: boolean = Object.keys(breadcrumbsParams).includes(mb);
      if (isParam) {
        // param
        breadcrumb["text"] = breadcrumbsParams[mb];
      } else {
        breadcrumb["text"] = mb;
      }

      const linkable = meta["linkable"] && !isLast;
      //disabled
      breadcrumb["disabled"] = !linkable;

      // href
      if (isParam) {
        path = route.params[mb];
      }
      link += "/" + path;

      if (linkable) {
        breadcrumb["href"] = link;
      }

      breadcrumbs.push(breadcrumb);
    }

    // add homepage link
    breadcrumbs = [this.homePage].concat(breadcrumbs);

    // check invalid links
    breadcrumbs
      .filter((b) => !Utils.isUndefined(b.href))
      .forEach((b) => {
        b.href = b.href!.replace("//", "/");
      });

    return breadcrumbs;
  }

  updateBreadcrumbsParams(params: any) {
    this.storeService.set(BREADCRUMBS_PARAMS, params);
  }
}
