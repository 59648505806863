import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/common/Models";
import { ApiService, ApiServiceFactory } from "@/common/services/ApiService";
import { API_CLIENTS_PATH, API_URL_BASE } from "@/config/ApiConfig";
import container from "@/config/InversifyConfig";
import SERVICE_IDENTIFIERS from "@/config/ServiceIdentifiers";
import { injectable } from "inversify";
import { ApiClients, ApiClient, ApiKeys } from "../Models";
import ApiClientService from "./ApiClientService";

@injectable()
export default class ApiClientServiceImpl implements ApiClientService {
  private apiService: ApiService;

  constructor() {
    const apiServiceFactory = container.get<ApiServiceFactory>(
      SERVICE_IDENTIFIERS.ApiServiceFactory
    );
    this.apiService = apiServiceFactory(API_URL_BASE);
  }

  getApiClients(
    apiKey?: string,
    offset?: number,
    count?: number,
    fields?: string,
    ignoreCountReached?: boolean
  ): Promise<ApiClients> {
    const requestConfig: AxiosRequestConfig = {
      params: {
        offset: offset,
        count: count,
        fields: fields,
      },
    };
    if (apiKey) {
      requestConfig["headers"] = {
        apikey: apiKey,
      };
    }
    return this.apiService
      .get<ApiClients>(API_CLIENTS_PATH, requestConfig, ignoreCountReached)
      .then((resp) => {
        const data: ApiClients[] = resp.flatMap((r) => r.data);
        return {
          total: data[0].total,
          apiClients: data.flatMap((t) => t.apiClients) || [],
        };
      });
  }
  listApiKeys(): Promise<ApiKeys> {
    return this.apiService.get<ApiKeys>(API_CLIENTS_PATH + "/keys")
    .then((resp) => {
      const data: ApiKeys[] = resp.flatMap((r) => r.data);
      return {
        apiKeys: data.flatMap((t) => t.apiKeys) || [],
      };
    });
  }
  postApiClient(apiClient: ApiClient): Promise<ApiClient> {
    return this.apiService
      .post<ApiClient>(API_CLIENTS_PATH, apiClient)
      .then((resp) => resp.data);
  }
  patchApiClient(id: string, apiClient: ApiClient): Promise<ApiResponse> {
    const url = [API_CLIENTS_PATH, id].join("/");
    return this.apiService
      .update<ApiResponse>(url, apiClient)
      .then((resp) => resp.data);
  }
  deleteApiClient(id: string): Promise<ApiResponse | null> {
    const url = [API_CLIENTS_PATH, id].join("/");
    return this.apiService
      .delete<ApiResponse>(url)
      .then((resp) => (resp ? resp.data : resp));
  }
}
