import { LOGIN_UI_URL } from "./../config/Constants";
import PermissionService from "@/common/services/PermissionService";
import UserService from "@/common/services/UserService";
import container from "@/config/InversifyConfig";
import SERVICE_IDENTIFIERS from "@/config/ServiceIdentifiers";
import {
  NavigationGuard,
  NavigationGuardNext,
  Route,
  RouteRecord,
} from "vue-router";
import StoreService from "@/common/services/StoreService";
import { STORE_KEYS } from "@/common/Store";
import Utils from "@/common/Utils";

const permissionService: PermissionService = container.get<PermissionService>(
  SERVICE_IDENTIFIERS.PermissionService
);
const userService: UserService = container.get<UserService>(
  SERVICE_IDENTIFIERS.UserService
);
const storeService: StoreService = container.get<StoreService>(
  SERVICE_IDENTIFIERS.StoreService
);

const loadUserSessionFromStore = function() {
  console.log("load user with session from store...");

  // user
  const user = storeService.get(STORE_KEYS.USER);
  if (user) {
    console.log("Found stored user: " + user);
    userService.setUser(user);
  }

  // // session cookie
  // const sessionCookie = storeService.get(STORE_KEYS.JSESSIONID);
  // if (sessionCookie) {
  //   console.log("Found stored session cookie: " + sessionCookie);

  //   // set cookie
  //   Utils.setCookie(sessionCookie);
  // }
};

const checkAuth: NavigationGuard<Vue> = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<any>
) => {
  // set permission rules for user if those are missing
  if (
    userService.getUser() &&
    (!permissionService.getAbility() ||
      permissionService.getAbility().rules.length === 0)
  ) {
    // missing permission setting
    console.log("user with no setted permission");

    loadUserSessionFromStore();
  }

  // no need to re-login in case of already logged in user
  if (to.path === LOGIN_UI_URL && userService.getUser()) {
    return next("/");
  }

  const isRestricted: boolean = to.matched.some(
    (record: RouteRecord) => record.meta.isRestricted
  );

  if (!isRestricted) {
    return next();
  }

  // restricted route
  if (!userService.getUser()) {
    return next({ name: "login" });
  }

  const canNavigate = to.matched.some(
    (record: RouteRecord) =>
      record.meta.isRestricted &&
      permissionService.getAbility()?.can("read", record.meta.resource)
  );

  if (!canNavigate) {
    return next({ name: "unauthorized" });
  }

  next();
};

export default checkAuth;
