import { STORE_KEYS } from "@/common/Store";
import { ProgressDialogData } from "../Models";
import { inject, injectable } from "inversify";
import SERVICE_IDENTIFIERS from "../../config/ServiceIdentifiers";
import StoreService from "./StoreService";
import ProgressService from "./ProgressService";

@injectable()
export default class ProgressServiceImpl implements ProgressService {
  private inProgress: boolean = false;

  @inject(SERVICE_IDENTIFIERS.StoreService)
  private storeService!: StoreService;

  showProgressDialog(isOpen: boolean, text?: string): void {
    this.inProgress = isOpen;

    const data: ProgressDialogData = {
      openDialog: isOpen,
      text: text,
    };
    this.storeService.set(STORE_KEYS.DIALOG_PROGRESS, data);
  }

  isInProgress(): boolean {
    console.log("isInProgress: " + this.inProgress);

    return this.inProgress;
  }
}
