
import Vue from "../../../AppVue";
import Component from "vue-class-component";
import StoreService from "../../../common/services/StoreService";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import { ConfirmDialogData } from "../../../common/Models";
import { STORE_KEYS } from "../../../common/Store";

@Component({})
export default class ConfirmDialog extends Vue {
  data: any = {
    openDialog: false,
    confirmDialogData: null,
    defaultMaxWidth: 500,
  };

  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );

  destroyed() {
    this.storeService.removeWatcher("ConfirmDialog", STORE_KEYS.DIALOG_CONFIRM);
  }

  created() {
    this.storeService.watchUpdate(
      STORE_KEYS.DIALOG_CONFIRM,
      "ConfirmDialog",
      (confirmDialogData: ConfirmDialogData) => {
        this.data["confirmDialogData"] = confirmDialogData;
        this.data["openDialog"] = true;
      }
    );
  }

  get dialogText() {
    if (this.data.confirmDialogData) {
      return this.data.confirmDialogData.text || "";
    }
    return "";
  }

  get confirmButtonText() {
    if (this.data.confirmDialogData) {
      return this.data.confirmDialogData.confirmButtonText || "";
    }
    return "";
  }

  get maxWidth() {
    if (this.data.confirmDialogData) {
      return this.data.confirmDialogData.maxWidth || this.data.defaultMaxWidth;
    }
    return this.data.defaultMaxWidth;
  }

  confirmed() {
    this.data.confirmDialogData.clickConfirmedFn(
      this.data.confirmDialogData.ctx
    );
    this.closeDialog();
  }

  cancel() {
    if (this.data.confirmDialogData.clickCancelFn) {
      this.data.confirmDialogData.clickCancelFn(
        this.data.confirmDialogData.ctx
      );
    }
    this.closeDialog();
  }

  closeDialog() {
    this.$set(this.data, "openDialog", false);
    this.$set(this.data, "confirmDialogData", null);
    this.storeService.set(STORE_KEYS.DIALOG_CONFIRM, null, {
      alertWatchers: false,
    });
  }
}
