import { SortingPagination } from "../../common/Models";
export interface ApiClient {
  id?: number;
  apiKey?: string;
  clientId?: string;
  password?: string;
  callForwardings?: CallForwarding[];
}

export interface ApiClients {
  total: number;
  apiClients: ApiClient[];
}

export interface ApiKeys {
  apiKeys: string[];
}

export interface CallForwarding {
  id?: number;
  apiClientId?: number;
  contactId?: number;
  entryTs?: Date;
  modifyTs?: Date;
  validFromTs?: string;
}

export interface ApiClientSearchModel extends SortingPagination {
  apiKey?: string;
}

export interface EditApiClientDialogDataModel {
  apiClient?: ApiClient;
  currentCallForwarding?: CallForwarding;
}

export interface EditApiClientDialogData {
  ctx: any;
  editMode: boolean;
  model: EditApiClientDialogDataModel;
  afterSubmitFn?(ctx: any): any;
  afterCancelFn?(ctx: any): any;
}

export interface EditCallForwardingDialogData {
  ctx: any;
  editMode: boolean;
  model: CallForwarding;
  validFromStart?: string;
  validFromEnd?: string;
  afterSubmitFn?(ctx: any): any;
  afterCancelFn?(ctx: any): any;
}

export interface AppStatus {
  deviceInfo: string;
  apiClientId: number;
  contactId?: number;
  lastHeartBeatTime?: Date;
  errorMessage?: string;
}

export interface AppStatusInfo {
  [appIdentifier: string]: AppStatus;
}

export enum ExpandedViewType {
  APP_STATUS = "APP_STATUS",
  CALL_FORWARDING = "CALL_FORWARDING",
}

export interface ExpandedView {
  viewType: ExpandedViewType;
  apiClient: ApiClient;
}
