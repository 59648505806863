import { ConfirmDialogData } from "./Models";
import { SortingPagination, PropChangeType } from "@/common/Models";
import moment, { Moment } from "moment-timezone";
import { TIMEZONE_BUDAPEST } from "../config/Constants";
import Vue from "vue";
import StoreService from "./services/StoreService";
import container from "@/config/InversifyConfig";
import SERVICE_IDENTIFIERS from "@/config/ServiceIdentifiers";

export interface CustomPropChangeDef {
  applyCustomChangeTypeDef?: (
    propValue: any,
    origChangeType: undefined | PropChangeType
  ) => undefined | PropChangeType;
  applyCustomChangeDef?: (
    updateObj: Object,
    propKey: string,
    oldPropValue: any,
    propValue: any,
    registerChanges: undefined | boolean,
    origChangeType: undefined | PropChangeType
  ) => void;
}

export default class Utils {
  private static storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );

  static enumKeys(enumVar: any) {
    return Object.keys(enumVar).filter(
      (k) => typeof enumVar[k as any] !== "number"
    );
  }

  static enumValues(enumVar: any) {
    const keys = Utils.enumKeys(enumVar);
    return keys.map((k) => enumVar[k as any]);
  }

  static getOr(prop: any, orValue: any) {
    return typeof prop !== "undefined" ? prop : orValue;
  }

  static fromOrderByDesc(orderBy: string, desc: boolean) {
    return (desc ? "-" : "+") + orderBy;
  }

  static toOrderByDesc(orderByDesc: string) {
    const desc: boolean = orderByDesc.substr(0, 1) === "-" ? true : false;
    const orderBy: string = orderByDesc.substring(1);
    return {
      sortBy: orderBy,
      sortDesc: desc,
    };
  }

  static enumKeysByValues(enumObj: any, values: any[]) {
    return Object.keys(enumObj).filter((key) => values.includes(enumObj[key]));
  }

  static isArray(a: any) {
    return Array.isArray(a);
  }

  static isObject(o: any) {
    return o === Object(o) && !Utils.isArray(o) && typeof o !== "function";
  }

  static isNumber(n: any) {
    return Number.isNaN(n);
  }

  static isUndefined(val: any) {
    return typeof val == "undefined";
  }

  static extractSortingPagination(searchModel: SortingPagination) {
    if (this.isUndefined(searchModel) || searchModel == null) {
      return null;
    }

    return {
      sortParams: searchModel.sortParams,
      offset: searchModel.offset,
      count: searchModel.count,
    };
  }

  static applyFnOnObjectElems(obj: Object, fn: Function) {
    if (!this.isObject(obj)) {
      return;
    }
    let walkedObj: any = {};
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; ++i) {
      let key: string = keys[i];
      console.log("key: " + key);

      const value = (<any>obj)[key];

      if (this.isObject(value)) {
        if (Object.keys(value).length > 0) {
          walkedObj[key] = this.applyFnOnObjectElems(value, fn);
        }
      } else {
        const newValue = fn(key, value);
        console.log(newValue);

        walkedObj[key] = newValue;
      }
      return walkedObj;
    }
  }

  private static applyCustomChange(
    updateObj: Object,
    propKey: string,
    oldPropValue: any,
    propValue: any,
    registerChanges: undefined | boolean,
    origChangeType: undefined | PropChangeType,
    customChangeDefs?: { [propKey: string]: CustomPropChangeDef }
  ): void {
    let changeDefFn: CustomPropChangeDef | undefined = undefined;
    if (customChangeDefs) {
      changeDefFn = (<any>customChangeDefs)[propKey];
    }

    if (registerChanges) {
      if (!(<any>updateObj)["_changes"]) {
        (<any>updateObj)["_changes"] = {};
      }

      if (origChangeType) {
        (<any>updateObj)["_changes"][propKey] = origChangeType;
      }

      if (changeDefFn && changeDefFn.applyCustomChangeTypeDef) {
        (<any>updateObj)["_changes"][
          propKey
        ] = changeDefFn.applyCustomChangeTypeDef(propValue, origChangeType);
      }
    }

    if (changeDefFn && changeDefFn.applyCustomChangeDef) {
      changeDefFn.applyCustomChangeDef(
        updateObj,
        propKey,
        oldPropValue,
        propValue,
        registerChanges,
        origChangeType
      );
    }
  }

  static modelChangedProps(
    oldModel: any,
    newModel: any,
    registerChanges?: boolean,
    keysToInsertInstantly?: string[],
    keysToIgnore?: string[],
    customChangeDefs?: { [propKey: string]: CustomPropChangeDef }
  ) {
    if (this.isUndefined(oldModel)) {
      return newModel;
    }

    let updateObj = {};
    const keys = Object.keys(newModel);
    for (let i = 0; i < keys.length; ++i) {
      let key: string = keys[i];
      const oldValue = oldModel[key];
      let newValue = newModel[key];

      if ((keysToIgnore || []).indexOf(key) > -1) {
        this.applyCustomChange(
          updateObj,
          key,
          oldValue,
          newValue,
          registerChanges,
          PropChangeType.IGNORED,
          customChangeDefs
        );
        continue;
      }

      if ((keysToInsertInstantly || []).indexOf(key) > -1) {
        (<any>updateObj)[key] = newValue;
        this.applyCustomChange(
          updateObj,
          key,
          oldValue,
          newValue,
          registerChanges,
          PropChangeType.AUTO_INSERTED,
          customChangeDefs
        );
        continue;
      }

      if (!this.isUndefined(oldValue) && this.isUndefined(newValue)) {
        (<any>updateObj)[key] = null;
        this.applyCustomChange(
          updateObj,
          key,
          oldValue,
          newValue,
          registerChanges,
          undefined,
          customChangeDefs
        );
        continue;
      }

      if (this.isUndefined(oldValue) && newValue == null) {
        continue;
      }

      if (typeof newValue == "string" && newValue == "") {
        if (this.isUndefined(oldValue) || oldValue == null) {
          continue;
        }
      }

      if (this.isUndefined(oldValue)) {
        (<any>updateObj)[key] = newValue;
        this.applyCustomChange(
          updateObj,
          key,
          oldValue,
          newValue,
          registerChanges,
          PropChangeType.ADDED,
          customChangeDefs
        );
        continue;
      }

      if (typeof newValue != typeof oldValue) {
        (<any>updateObj)[key] = newValue;
        this.applyCustomChange(
          updateObj,
          key,
          oldValue,
          newValue,
          registerChanges,
          PropChangeType.CHANGED,
          customChangeDefs
        );
        continue;
      }

      // examining equality
      if (this.isArray(newValue)) {
        const oldA: any[] = oldValue;
        const newA: any[] = newValue;

        if (oldA.length != newA.length) {
          (<any>updateObj)[key] = newValue;
          this.applyCustomChange(
            updateObj,
            key,
            oldValue,
            newValue,
            registerChanges,
            PropChangeType.CHANGED,
            customChangeDefs
          );
          continue;
        }

        for (let j = 0; j < newA.length; ++j) {
          let changed = false;
          if (this.isObject(oldA[j]) && this.isObject(newA[j])) {
            changed = !this.isEmptyObject(
              this.modelChangedProps(oldA[j], newA[j])
            );
          } else {
            changed = oldA.indexOf(newA[j]) == -1;
          }

          if (changed) {
            (<any>updateObj)[key] = newValue;
            this.applyCustomChange(
              updateObj,
              key,
              oldValue,
              newValue,
              registerChanges,
              PropChangeType.CHANGED,
              customChangeDefs
            );
            break;
          }
        }
      } else if (this.isObject(newValue)) {
        const changedPropsObj = this.modelChangedProps(oldValue, newValue);
        if (Object.keys(changedPropsObj).length > 0) {
          (<any>updateObj)[key] = changedPropsObj;
          this.applyCustomChange(
            updateObj,
            key,
            oldValue,
            newValue,
            registerChanges,
            PropChangeType.CHANGED,
            customChangeDefs
          );
        }
      } else {
        if (newValue != oldValue) {
          (<any>updateObj)[key] = newValue;

          if (
            !this.isUndefined(oldValue) &&
            oldValue != null &&
            (this.isUndefined(newValue) || newValue == null)
          ) {
            this.applyCustomChange(
              updateObj,
              key,
              oldValue,
              newValue,
              registerChanges,
              PropChangeType.REMOVED,
              customChangeDefs
            );
          } else {
            this.applyCustomChange(
              updateObj,
              key,
              oldValue,
              newValue,
              registerChanges,
              PropChangeType.CHANGED,
              customChangeDefs
            );
          }
        }
      }
    }
    return updateObj;
  }

  static isEmptyObject(obj: any) {
    return !obj || Object.keys(obj).length == 0;
  }

  static isEmptyArray(arr: any[]) {
    return arr.length === 0;
  }

  static sortStringValueObjArray(objArray: any[], propName: string) {
    return objArray.sort((obj1, obj2) => {
      const sortA = (obj1[propName] + "").toLowerCase();
      const sortB = (obj2[propName] + "").toLowerCase();

      if (sortA < sortB) return -1;
      if (sortA > sortB) return 1;
      return 0;
    });
  }

  static deepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  static deepCopyArrayOfObjects(a: any[]): any[] {
    const b: any[] = [];
    a.forEach((o) => b.push(Object.assign({}, o)));
    return b;
  }

  static createNullableOptions(props: string[], value: any) {
    let obj: any = {};
    props.forEach((prop) => {
      obj[prop] = value;
    });
    return obj;
  }

  static transformEnumForUI(enumType: any) {
    const values = Utils.enumKeys(enumType);
    const texts = Utils.enumValues(enumType);
    let a = [];
    for (let i = 0; i < texts.length; ++i) {
      a.push({
        text: texts[i],
        value: values[i],
      });
    }
    return a;
  }

  static focusToFormField(
    ctx: any,
    selector: string | undefined,
    scroll: string | number | HTMLElement,
    scrollOptions?: object
  ) {
    if (scroll && ctx.$vuetify) {
      ctx.$vuetify.goTo(scroll, scrollOptions);
    }

    if (!this.isUndefined(selector) && selector != "") {
      const el = (ctx.$el || document).querySelector(
        selector
      ) as HTMLInputElement;

      if (el != null) {
        (ctx.$nextTick || new Vue().$nextTick)(() => {
          el.focus();
        });
      }
    }
  }

  static insertText(text1: string, pos: number, text2: string) {
    return [text1.slice(0, pos), text2, text1.slice(pos)].join("");
  }

  static replaceAll(text: string, oldStr: string, newStr: string) {
    return text.split(oldStr).join(newStr);
  }

  static reverseText(text: string) {
    return text
      .split("")
      .reverse()
      .join("");
  }

  static isValidRegex(regexStr: string) {
    try {
      RegExp(regexStr);
    } catch (e) {
      return false;
    }
    return true;
  }

  static isProductionEnv() {
    return process.env.NODE_ENV == "production";
  }

  static transformBooleanForUI(bData: boolean | undefined) {
    if (Utils.isUndefined(bData)) {
      return;
    }
    return bData ? "Igen" : "Nem";
  }

  static isDeletedFromObject(old: any, current: any, field: string): boolean {
    if (!this.isObject(current) || !this.isObject(old)) return false;
    return (
      !Utils.isUndefined(old[field]) &&
      old[field] != null &&
      current[field] == null
    );
  }

  static getCookie(name: string, source?: any): string | null {
    const nameLenPlus = name.length + 1;
    const cookieSource = source ? source : document.cookie;
    return (
      cookieSource
        .split(";")
        .map((c: any) => c.trim())
        .filter((cookie: any) => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map((cookie: any) => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }

  static setCookie(cookieString: string) {
    document.cookie = cookieString;
  }

  static formatDateTime(dt: string, format: string) {
    if (!dt) {
      return "";
    }
    return moment(dt)
      .tz(TIMEZONE_BUDAPEST)
      .format(format);
  }

  static camelToSnakeCase(str: string) {
    let snakedStr = str.replace(/[a-z][A-Z]/g, (letters) =>
      `${letters[0]}_${letters[1]}`.toLowerCase()
    );
    return snakedStr.startsWith("_") ? snakedStr.substring(1) : snakedStr;
  }

  static firstLetterToCapitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static isNullArray(array: any[]): boolean {
    if (!array) {
      return true;
    }

    const validElem = array.find((el) => !this.isUndefined(el) && el != null);
    return this.isUndefined(validElem);
  }

  static subtractArrayFromArray(a: any[], b: any[]): any[] {
    return a.filter((i) => b.indexOf(i) === -1);
  }

  static arraysEqual(a: any[], b: any[], sortFn: any) {
    let arrayA = a;
    let arrayB = b;
    arrayA = a.slice();
    arrayB = b.slice();
    arrayA.sort(sortFn);
    arrayB.sort(sortFn);
    if (arrayA === arrayB) return true;
    if (arrayA == null || arrayB == null) return false;
    if (arrayA.length !== arrayB.length) return false;

    for (var i = 0; i < arrayA.length; ++i) {
      if (arrayA[i] !== arrayB[i]) return false;
    }
    return true;
  }

  static createUnsavedConfirmDialogData(
    text: string,
    next: Function
  ): ConfirmDialogData {
    return {
      ctx: { next: next },
      text: text,
      confirmButtonText: "Oldal elhagyása",
      maxWidth: 600,
      clickConfirmedFn: (ctx) => {
        ctx.next();
      },
      clickCancelFn: (ctx) => {
        ctx.next(false);
      },
    };
  }

  static fileToByteArray(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        let fileByteArray: any[] = [];
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
          if (evt.target && evt.target.readyState == FileReader.DONE) {
            const arrayBuffer: ArrayBuffer = evt.target.result as ArrayBuffer;
            const array = new Uint8Array(arrayBuffer);
            for (let byte of array) {
              fileByteArray.push(byte);
            }
          }
          resolve(fileByteArray);
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  static areSame(
    m1: Moment,
    m2: Moment,
    unitOfTime: moment.unitOfTime.StartOf
  ): boolean {
    if (!m1 || !m2 || !m1.isValid() || !m2.isValid()) return false;
    else if (!m1 && !m2) return true;
    return moment(m1)
      .startOf(unitOfTime)
      .isSame(moment(m2).startOf(unitOfTime));
  }

  static isBefore(
    m1: Moment,
    m2: Moment,
    unitOfTime: moment.unitOfTime.StartOf
  ): boolean {
    if (!m1 || !m2 || !m1.isValid() || !m2.isValid()) return false;
    else if (!m1 && !m2) return true;
    return moment(m1)
      .startOf(unitOfTime)
      .isBefore(moment(m2).startOf(unitOfTime));
  }

  static isAfter(
    m1: Moment,
    m2: Moment,
    unitOfTime: moment.unitOfTime.StartOf
  ): boolean {
    if (!m1 || !m2 || !m1.isValid() || !m2.isValid()) return false;
    else if (!m1 && !m2) return true;
    return moment(m1)
      .startOf(unitOfTime)
      .isAfter(moment(m2).startOf(unitOfTime));
  }

  static getFutureData<T>(key: string): Promise<T> {
    const UTILS: string = "Utils";

    return new Promise<T>((resolve) => {
      try {
        this.storeService.watchUpdate(
          key,
          UTILS,
          (data: T) => {
            this.storeService.removeWatcher(UTILS, key);
            resolve(data);
          },
          true
        );
      } catch (e) {
        console.log(e);
        this.storeService.removeWatcher(UTILS, key);
      }
    });
  }
}
