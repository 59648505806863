import { STORE_KEYS } from "./../Store";
import { User } from "@/common/Models";
import { inject, injectable } from "inversify";
import StoreService from "./StoreService";
import SERVICE_IDENTIFIERS from "../../config/ServiceIdentifiers";
import UserService from "./UserService";
import PermissionService from "./PermissionService";

@injectable()
export default class UserServiceImpl implements UserService {
  @inject(SERVICE_IDENTIFIERS.PermissionService)
  private permissionService!: PermissionService;

  @inject(SERVICE_IDENTIFIERS.StoreService)
  private storeService!: StoreService;

  getUser(): User | null {
    return this.storeService.get(STORE_KEYS.USER);
  }

  setUser(user: User | null): void {
    this.permissionService.updateAbility(user);

    // store user object
    this.storeService.set(STORE_KEYS.USER, user, {
      alertWatchers: true,
      persisted: true,
    });
  }
}
