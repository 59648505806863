import Vue from "vue";
import App from "@/App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import VueAxios from "vue-axios";
import axios from "axios";
import VueDraggable from "vue-draggable";
import router from "./router/index";
import VueI18n from "vue-i18n";
import MenuListItem from "./common/components/menu/MenuListItem.vue";
import MenuListItemGroup from "./common/components/menu/MenuListItemGroup.vue";
import PermissionService from "./common/services/PermissionService";
import container from "./config/InversifyConfig";
import SERVICE_IDENTIFIERS from "./config/ServiceIdentifiers";
import { abilitiesPlugin } from "@casl/vue";

import "@/assets/styles/fonts.scss";
import "@/assets/styles/app.scss";
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCheck,
  mdiClose,
  mdiMagnify,
  mdiPlus,
  mdiPencil,
  mdiContentCopy,
  mdiDelete,
  mdiCloseCircle,
  mdiContentSaveOutline,
  mdiMenu,
  mdiArrowRightBold,
  mdiLogout,
  mdiPhoneForward,
  mdiCellphone,
  mdiAlert,
} from "@mdi/js";
import StoreService from "./common/services/StoreService";

const permissionService: PermissionService = container.get<PermissionService>(
  SERVICE_IDENTIFIERS.PermissionService
);
const storeService: StoreService = container.get<StoreService>(
  SERVICE_IDENTIFIERS.StoreService
);

// config Vue proto
Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueDraggable);
Vue.use(VueI18n);

Vue.use(VueAxios, axios);

// register components globally because of recursive nesting
Vue.component("MenuListItem", MenuListItem);
Vue.component("MenuListItemGroup", MenuListItemGroup);

Vue.use(abilitiesPlugin, permissionService.createAbilityWithNoRules());

// add icons to Vue proto
Vue.prototype.$icons = {
  mdiChevronUp: mdiChevronUp,
  mdiChevronDown: mdiChevronDown,
  mdiChevronLeft: mdiChevronLeft,
  mdiChevronRight: mdiChevronRight,
  mdiCheck: mdiCheck,
  mdiClose: mdiClose,
  mdiMagnify: mdiMagnify,
  mdiPlus: mdiPlus,
  mdiContentCopy: mdiContentCopy,
  mdiPencil: mdiPencil,
  mdiDelete: mdiDelete,
  mdiCloseCircle: mdiCloseCircle,
  mdiContentSaveOutline: mdiContentSaveOutline,
  mdiMenu: mdiMenu,
  mdiArrowRightBold: mdiArrowRightBold,
  mdiLogout: mdiLogout,
  mdiPhoneForward: mdiPhoneForward,
  mdiCellphone: mdiCellphone,
  mdiAlert: mdiAlert,
};

Vue.prototype.$store = storeService.initStoreFromStorage();

const vue = new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
storeService.set("vue", vue);
