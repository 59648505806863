import { STORE_KEYS } from "./../Store";
import Store from "../Store";
import StoreService from "./StoreService";
import { inject, injectable } from "inversify";
import { StoreConfig } from "../Models";
import SERVICE_IDENTIFIERS from "@/config/ServiceIdentifiers";

@injectable()
export default class StoreServiceImpl implements StoreService {
  @inject(SERVICE_IDENTIFIERS.Store)
  private store!: Store;

  initStoreFromStorage(): Store {
    for (let k in STORE_KEYS) {
      const v = localStorage.getItem(k);
      if (v) {
        console.log("Loaded key -> value: " + k + " -> " + v);
        this.store.set(k, JSON.parse(v), false);
      }
    }
    return this.store;
  }

  get(propName: string): any {
    return this.store.get(propName);
  }

  set(propName: string, propVal: any, config?: StoreConfig): void {
    if (config && config.persisted) {
      localStorage.setItem(propName, JSON.stringify(propVal));
    }
    const alertWatchers = config && config.alertWatchers;
    this.store.set(propName, propVal, alertWatchers);
  }

  update(propName: string, newPropVal: any, config?: StoreConfig): void {
    if (config && config.persisted) {
      localStorage.setItem(propName, JSON.stringify(newPropVal));
    }
    const alertWatchers = config && config.alertWatchers;
    this.store.update(propName, newPropVal, alertWatchers);
  }

  watchUpdate(
    propName: string,
    sourceIdentifier: string,
    fn: Function,
    immediateAlert?: boolean
  ) {
    this.store.watchUpdate(propName, sourceIdentifier, fn, immediateAlert);
  }

  removeWatcher(sourceIdentifier: string, propName: string): void {
    this.store.removeWatcher(sourceIdentifier, propName);
  }

  hasWatchers(propName: string): boolean {
    return this.store.hasWatchers(propName);
  }
}
