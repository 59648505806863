
import Vue from "../../AppVue";
import Component from "vue-class-component";
import StoreService from "../../common/services/StoreService";
import container from "../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../config/ServiceIdentifiers";
import { STORE_KEY_CONTEXT_MENU } from "../../config/Constants";
import { ContextMenuData, ContextMenuItem } from "../Models";

@Component({
  components: {},
})
export default class ContextMenu extends Vue {
  data: any = {
    openMenu: false,
    contextMenuData: null,
  };

  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );

  destroyed() {
    this.storeService.removeWatcher(ContextMenu.name, STORE_KEY_CONTEXT_MENU);
  }

  created() {
    this.storeService.watchUpdate(
      STORE_KEY_CONTEXT_MENU,
      ContextMenu.name,
      (contextMenuData: ContextMenuData) => {
        this.data["contextMenuData"] = contextMenuData;
        this.data["openMenu"] = true;
      }
    );
  }

  get menuPositionX(): number {
    if (this.data.contextMenuData) {
      return this.data.contextMenuData.positionX || 0;
    }
    return 0;
  }

  get menuPositionY(): number {
    if (this.data.contextMenuData) {
      return this.data.contextMenuData.positionY || 0;
    }
    return 0;
  }

  get menuItems(): ContextMenuItem[] {
    if (this.data.contextMenuData) {
      return this.data.contextMenuData.menuItems || [];
    }
    return [];
  }

  clickItem(item: any) {
    item.clickFn(this.data.contextMenuData.ctx);
    this.closeMenu();
  }

  closeMenu() {
    this.$set(this.data, "openMenu", false);
    this.$set(this.data, "contextMenuData", null);
  }
}
