
import Component from "vue-class-component";
import {
  ConfirmDialogData,
  ContextMenuItem,
  OkDialogData,
} from "../../../common/Models";
import ApiClientService from "../services/ApiClientService";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import CallForwardingService from "../services/CallForwardingService";
import {
  ApiClient,
  AppStatus,
  AppStatusInfo,
  CallForwarding,
  EditApiClientDialogData,
  EditCallForwardingDialogData,
  ExpandedViewType,
} from "../Models";
import StoreService from "../../../common/services/StoreService";
import ItemList from "../../../common/components/ItemList.vue";
import EditApiClientDialog from "./EditApiClientDialog.vue";
import { STORE_KEYS } from "../../../common/Store";
import Utils from "../../../common/Utils";
import {
  DATE_FORMAT_HU,
  TIMEZONE_BUDAPEST,
  TIME_FORMAT,
} from "../../../config/Constants";
import moment from "moment-timezone";
import { Watch } from "vue-property-decorator";
import EditCallForwardingDialog from "./EditCallForwardingDialog.vue";
import AppStatusService from "../services/AppStatusService";
import { Contact } from "../../../modules/contacts/Models";
import ExpandedCallForwardings from "./ExpandedCallForwardings.vue";
import ExpandedApps from "./ExpandedApps.vue";

@Component({
  components: {
    EditApiClientDialog,
    EditCallForwardingDialog,
    ExpandedCallForwardings,
    ExpandedApps,
  },
})
export default class CallForwardingList extends ItemList {
  headers = [
    {
      text: "Kliens azonosító",
      align: "center",
      sortable: false,
      value: "clientId",
      show: true,
    },
    {
      text: "Kliens jelszó",
      align: "center",
      sortable: false,
      value: "password",
      show: true,
    },
    {
      text: "Aktuális átirányítás",
      align: "center",
      sortable: false,
      value: "callForwardings",
      show: true,
    },
    {
      text: "Módosítva",
      align: "center",
      sortable: false,
      value: "callForwardingModified",
      show: true,
    },
    {
      text: "Érvényes ekkortól",
      align: "center",
      sortable: false,
      value: "validFrom",
      show: true,
    },
    { text: "", sortable: false, value: "actions", show: true },
  ];
  itemIdToDelete: string | null = null;
  expandedRows: ApiClient[] = [];
  data: any = {
    activeCallForwardings: {},
    futureCallForwardings: {},
    appStatuses: {},
    expandMappings: {},
  };

  apiClientService: ApiClientService = container.get<ApiClientService>(
    SERVICE_IDENTIFIERS.ApiClientService
  );
  callForwardingService: CallForwardingService =
    container.get<CallForwardingService>(
      SERVICE_IDENTIFIERS.CallForwardingService
    );
  storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );
  appStatusService: AppStatusService = container.get<AppStatusService>(
    SERVICE_IDENTIFIERS.AppStatusService
  );

  destroyed() {
    this.appStatusService.unsubscribe();
    this.storeService.removeWatcher(
      "CallForwardingList",
      STORE_KEYS.APP_STATUS
    );
  }

  created() {
    this.storeService.watchUpdate(
      STORE_KEYS.APP_STATUS,
      "CallForwardingList",
      (data: AppStatusInfo) => {
        const appStatuses = this.transformAppStatusInfo(data);

        const removedApiClientIds: number[] = Utils.subtractArrayFromArray(
          Object.keys(this.data.appStatuses),
          Object.keys(appStatuses)
        );
        removedApiClientIds.forEach((removedApiClientId) =>
          this.closeExpandedView(removedApiClientId)
        );

        this.$set(this.data, "appStatuses", appStatuses);
      }
    );
    this.appStatusService.subscribe();
  }

  @Watch("items", { immediate: true })
  async onItemsChange() {
    this.data["contacts"] = await Utils.getFutureData(
      STORE_KEYS.CFW + ".contacts"
    );

    const apiClients: ApiClient[] = this.items;
    const activeCallForwardings: { [apiClientId: number]: CallForwarding } = {};
    const futureCallForwardings: {
      [apiClientId: number]: CallForwarding[];
    } = {};
    apiClients
      .flatMap((apiClient) => apiClient.callForwardings || [])
      .forEach((callForwarding) => {
        if (this.isActiveCallForwarding(callForwarding)) {
          activeCallForwardings[callForwarding.apiClientId!] = callForwarding;
        } else {
          let futureCfs = futureCallForwardings[callForwarding.apiClientId!];
          if (!futureCfs) {
            futureCfs = [];
            futureCallForwardings[callForwarding.apiClientId!] = futureCfs;
          }
          futureCfs.push(callForwarding);
        }
      });
    this.$set(this.data, "activeCallForwardings", activeCallForwardings);
    this.$set(this.data, "futureCallForwardings", futureCallForwardings);
  }

  setContextMenuItems(
    contextMenuItems: ContextMenuItem[],
    contextMenuRowItem: ApiClient
  ): void {
    if (this.hasFutureCallForwarding(contextMenuRowItem)) {
      contextMenuItems.push({
        title: "Jövőbeli átirányítások",
        icon: "mdiPhoneForward",
        clickFn: () => {
          this.showExpandedView(
            contextMenuRowItem,
            ExpandedViewType.CALL_FORWARDING
          );
        },
      });
    } else {
      contextMenuItems.push({
        title: "Jövőbeli átirányítás hozzáadása",
        icon: "mdiPlus",
        clickFn: () => {
          this.openEditCallForwardingDialog({
            apiClientId: contextMenuRowItem.id,
          });
        },
      });
    }
    if (this.hasRelatedApps(contextMenuRowItem)) {
      contextMenuItems.push({
        title: Object.keys(this.data.appStatuses[contextMenuRowItem.id!] || {})
          .length
          ? "Kapcsolódó telefon"
          : "Kapcsolódó telefonok",
        icon: "mdiCellphone",
        clickFn: () => {
          this.showExpandedView(
            contextMenuRowItem,
            ExpandedViewType.APP_STATUS
          );
        },
      });
    }
    contextMenuItems.push({
      title: "Módosítás",
      icon: "mdiPencil",
      clickFn: () => {
        this.openEditApiClientDialog(contextMenuRowItem);
      },
    });
    contextMenuItems.push({
      title: "Törlés",
      icon: "mdiDelete",
      clickFn: () => {
        this.deleteApiClient(contextMenuRowItem);
      },
    });
  }

  deleteApiClient(item: ApiClient) {
    console.log("delete apiClient1");

    this.itemIdToDelete = item.id + "";

    const confirmDialogData: ConfirmDialogData = {
      ctx: this,
      text: "Biztosan törli az API klienst?",
      confirmButtonText: "API kliens törlése",
      clickConfirmedFn: (ctx) => {
        this.apiClientService
          .deleteApiClient(this.itemIdToDelete!)
          .then((resp: any) => {
            this.itemIdToDelete = null;
            this.openOkDialogAfterDeleteSuccess();
          });
      },
    };
    this.storeService.set(STORE_KEYS.DIALOG_CONFIRM, confirmDialogData);
  }

  openEditApiClientDialog(item?: ApiClient) {
    const dialogData: EditApiClientDialogData = {
      ctx: this,
      editMode: item ? true : false,
      model: item
        ? {
            apiClient: item,
            currentCallForwarding: this.data.activeCallForwardings[item.id!],
          }
        : { apiClient: {}, currentCallForwarding: {} },
      afterSubmitFn: (ctx) => {
        ctx.reload();
      },
    };
    this.storeService.set(STORE_KEYS.DIALOG_EDIT_API_CLIENT, dialogData);
  }

  openEditCallForwardingDialog(item: CallForwarding) {
    const editMode: boolean = !!!Utils.isEmptyObject(
      Utils.modelChangedProps({}, item, undefined, undefined, ["apiClientId"])
    );

    const nowPlusOneMin = moment.tz(TIMEZONE_BUDAPEST).add(1, "m").format();

    const dialogData: EditCallForwardingDialogData = {
      ctx: this,
      editMode: editMode,
      model: item,
      validFromStart: nowPlusOneMin,
      afterSubmitFn: (ctx) => {
        ctx.reload();
      },
    };
    this.storeService.set(STORE_KEYS.DIALOG_EDIT_CALLFORWARDING, dialogData);
  }

  openOkDialogAfterDeleteSuccess() {
    const okDialogData: OkDialogData = {
      ctx: this,
      text: "Sikeres törlés!",
      afterOkFn: (ctx) => {
        ctx.reload();
      },
    };
    this.storeService.set(STORE_KEYS.DIALOG_OK, okDialogData);
  }

  isActiveCallForwarding(callForwarding: CallForwarding): boolean {
    return (
      callForwarding &&
      !moment(callForwarding.validFromTs!).isAfter(moment(new Date()))
    );
  }

  isFutureCallForwarding(callForwarding: CallForwarding): boolean {
    return !this.isActiveCallForwarding(callForwarding);
  }

  hasFutureCallForwarding(apiClient: ApiClient): boolean {
    if (!apiClient.callForwardings || apiClient.callForwardings.length === 0)
      return false;

    for (let i = 0; i < apiClient.callForwardings.length; ++i) {
      if (this.isFutureCallForwarding(apiClient.callForwardings[i]))
        return true;
    }
    return false;
  }

  hasRelatedApps(apiClient: ApiClient): boolean {
    if (!this.data.appStatuses[apiClient.id!]) return false;
    return Object.keys(this.data.appStatuses[apiClient.id!]).length > 0;
  }

  showExpandedView(apiClient: ApiClient, viewType: string) {
    const indexExpanded = this.expandedRows.findIndex(
      (ac: ApiClient) => ac.id === apiClient.id
    );
    if (indexExpanded > -1) {
      const previousExpandViewType = this.data.expandMappings[apiClient.id!];
      if (previousExpandViewType === viewType) {
        // close view
        delete this.data.expandMappings[apiClient.id!];
        this.expandedRows.splice(indexExpanded, 1);
      } else {
        // change view
        this.data.expandMappings[apiClient.id!] = viewType;
        // update model to force UI
        this.expandedRows.splice(indexExpanded, 1, apiClient);
      }
    } else {
      this.expandedRows.push(apiClient);
      this.data.expandMappings[apiClient.id!] = viewType;
    }
  }

  closeExpandedView(apiClientId: number) {
    const apiClientIdx = this.expandedRows.findIndex(
      (ac: ApiClient) => ac.id == apiClientId
    );
    if (apiClientIdx > -1) {
      this.expandedRows.splice(apiClientIdx, 1);
      delete this.data.expandMappings[apiClientId];
    }
  }

  formatDateTime(dt: string) {
    return Utils.formatDateTime(dt, DATE_FORMAT_HU + " " + TIME_FORMAT);
  }

  isForwarded(callForwarding: CallForwarding) {
    return callForwarding && callForwarding.contactId;
  }

  showContact(contactId: number) {
    if (this.data.contacts) {
      const contact: Contact | undefined = (
        this.data.contacts as Contact[]
      ).find((contact) => contact.id === contactId);
      if (contact) {
        return `${contact.name} (+36${contact.msisdn})`;
      }
    }
    return "ismeretlen kontakt";
  }

  transformAppStatusInfo(data: AppStatusInfo): {
    [apiClientId: number]: { [appIdentifier: string]: AppStatus };
  } {
    const o: {
      [apiClientId: number]: { [appIdentifier: string]: AppStatus };
    } = {};
    Object.entries(data).forEach((e) => {
      const appIdentifier: string = e[0];
      const appStatus: AppStatus = e[1];
      const apiClientId: number = appStatus.apiClientId;

      o[apiClientId] = {
        ...o[apiClientId],
        [appIdentifier]: appStatus,
      };
    });
    return o;
  }
}
