
import Component from "vue-class-component";
import BreadcrumbsService from "../../../common/services/BreadcrumbsService";
import StoreService from "../../../common/services/StoreService";
import TitleService from "../../../common/services/TitleService";
import Utils from "../../../common/Utils";
import "moment-timezone";
import BackToItemService from "../../../common/services/BackToItemService";
import CallForwardingList from "../components/CallForwardingList.vue";
import container from "../../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../../config/ServiceIdentifiers";
import ApiClientService from "../services/ApiClientService";
import {
  BIG_QUERY_LIMIT,
  DATE_FORMAT_HU,
  PAGINATION_ITEMS_PER_PAGE,
  STORE_KEY_SUFFIX_SEARCHMODEL,
  TIMEZONE_BUDAPEST,
  TIME_FORMAT,
} from "../../../config/Constants";
import { STORE_KEYS } from "../../../common/Store";
import { ApiClientSearchModel, AppStatusInfo } from "../Models";
import Page from "../../../common/components/page/Page.vue";
import ContactService from "../../../modules/contacts/services/ContactService";
import moment from "moment-timezone";

@Component({
  components: {
    Page,
    CallForwardingList,
  },
})
export default class CallForwardingsPage extends Page {
  storePrefix = STORE_KEYS.CFW;
  searchModelStoreKey = this.storePrefix + STORE_KEY_SUFFIX_SEARCHMODEL;
  defaultSorting = "-id";
  loading = false;

  appStatusReceived: string | null = null;

  private apiClientService: ApiClientService = container.get<ApiClientService>(
    SERVICE_IDENTIFIERS.ApiClientService
  );
  private contactService: ContactService = container.get<ContactService>(
    SERVICE_IDENTIFIERS.ContactService
  );

  private storeService: StoreService = container.get<StoreService>(
    SERVICE_IDENTIFIERS.StoreService
  );
  private breadcrumbsService: BreadcrumbsService =
    container.get<BreadcrumbsService>(SERVICE_IDENTIFIERS.BreadcrumbsService);
  private titleService: TitleService = container.get<TitleService>(
    SERVICE_IDENTIFIERS.TitleService
  );
  private backToItemService: BackToItemService =
    container.get<BackToItemService>(SERVICE_IDENTIFIERS.BackToItemService);

  destroyed() {
    this.storeService.removeWatcher(
      "CallForwardingsPage",
      this.searchModelStoreKey
    );
    this.updateSearchModelWithValidValues();

    this.storeService.removeWatcher(
      "CallForwardingsPage",
      STORE_KEYS.APP_STATUS
    );
  }

  updateSearchModelWithValidValues() {
    const lastValidSearchModel = Utils.deepCopy(
      this.storeService.get(this.searchModelStoreKey + ".lastValid")
    );
    const sp = Utils.extractSortingPagination(
      this.storeService.get(this.searchModelStoreKey)
    );
    const sm = Object.assign({}, lastValidSearchModel, sp);
    this.storeService.set(this.searchModelStoreKey, sm, {
      alertWatchers: false,
    });
  }

  queryApiKeys() {
    this.apiClientService.listApiKeys().then((resp) => {
      this.storeService.set(this.storePrefix + ".apiKeys", resp.apiKeys);
    });
  }

  queryContacts() {
    this.contactService
      .getContacts(0, BIG_QUERY_LIMIT, false)
      .then((resp: any) => {
        const contacts = resp.contacts;
        this.storeService.set(this.storePrefix + ".contacts", contacts);
      });
  }

  created() {
    this.breadcrumbsService.updateBreadcrumbsParams({});

    this.storeService.watchUpdate(
      STORE_KEYS.APP_STATUS,
      "CallForwardingsPage",
      (data: AppStatusInfo) => {
        this.appStatusReceived = moment()
          .tz(TIMEZONE_BUDAPEST)
          .format(DATE_FORMAT_HU + " " + TIME_FORMAT);
      }
    );

    this.storeService.watchUpdate(
      this.searchModelStoreKey,
      "CallForwardingsPage",
      (searchModel: ApiClientSearchModel) => {
        this.storeService.set(
          this.searchModelStoreKey + ".lastValid",
          Utils.deepCopy(searchModel)
        );
        this.search(searchModel).then(() => {
          this.backToItemService.backToItem(this);
        });
      }
    );

    // init
    const searchModel: ApiClientSearchModel =
      this.storeService.get(this.searchModelStoreKey) || {};
    searchModel.offset = this.backToItemService.isActive()
      ? searchModel.offset
      : 0;
    searchModel.sortParams = searchModel.sortParams
      ? searchModel.sortParams
      : this.defaultSorting;
    searchModel.count = searchModel.count
      ? searchModel.count
      : PAGINATION_ITEMS_PER_PAGE;
    this.storeService.set(this.searchModelStoreKey, searchModel);
  }

  get title() {
    return this.titleService.getTitle(this.$route);
  }

  search(searchModel: ApiClientSearchModel) {
    this.loading = true;

    this.queryApiKeys();
    this.queryContacts();

    return this.apiClientService
      .getApiClients(
        searchModel.apiKey ? searchModel.apiKey : undefined,
        searchModel.offset ? searchModel.offset : undefined,
        searchModel.count ? searchModel.count : undefined,
        searchModel.fields ? searchModel.fields : undefined,
        true
      )
      .then((resp) => {
        this.loading = false;

        this.storeService.set(this.storePrefix + ".total", resp.total);
        const apiClients = Utils.sortStringValueObjArray(
          resp.apiClients,
          "clientId"
        );
        this.storeService.set(this.storePrefix + ".list", apiClients);
      });
  }
}
