
import Vue from "../../AppVue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import TitleService from "../../common/services/TitleService";
import { MenuItem } from "../Models";
import container from "../../config/InversifyConfig";
import SERVICE_IDENTIFIERS from "../../config/ServiceIdentifiers";

@Component({
  components: {},
})
export default class Menu extends Vue {
  @Prop({ required: true }) menuItems!: MenuItem[];
  @Prop({ required: true }) showTitle!: boolean;
  showMenu: boolean = false;

  titleService: TitleService = container.get<TitleService>(
    SERVICE_IDENTIFIERS.TitleService
  );

  data = {
    menuItemChildren: [],
    highlightedMenuKey: "",
  };

  @Watch("$route", { immediate: true, deep: true })
  onRouteChange() {
    this.showMenu = false;
    this.title = this.titleService.getTitle(this.$route);
  }

  get title() {
    return this.titleService.getTitle(this.$route);
  }

  set title(value: string) {}

  get menuItemChildren(): MenuItem[] {
    return this.data.menuItemChildren;
  }

  set menuItemChildren(children: MenuItem[]) {
    this.$set(this.data, "menuItemChildren", children);
    this.showMenu = true;
  }

  closeMenu() {
    if (this.showMenu == true) {
      this.showMenu = false;
      this.data["highlightedMenuKey"] = "";
    }
  }

  highlightMenuItem(menuKey: string): void {
    this.data["highlightedMenuKey"] = menuKey;
  }

  isHighlightedMenu(menuKey: string): boolean {
    if (!menuKey) {
      return false;
    }
    return this.showMenu && this.data["highlightedMenuKey"] === menuKey;
  }

  go(item: MenuItem) {
    if (item.name) {
      this.$router.push({ name: item.name });
    } else {
      this.$router.push({ path: item.link });
    }
  }
}
